import { useState } from 'react';

import buttonStyles from '../../styles/modal/BaggageInfo.module.css';

import { ReactComponent as LuggageIcon } from './../../assets/icons/xeu-no-bag.svg';
import { ReactComponent as DisabledLuggageIcon } from './../../assets/icons/xeu-no-bag-disabled.svg';
import { ReactComponent as ModalCheckIcon } from './../../assets/icons/xeu-modal-check.svg';

export const ModalBaggageButton = ({
	title,
	noBax,
	disable,
	onClick,
}: {
	title: string;
	disable?: boolean;
	noBax: boolean;
	onClick: () => void;
}) => {
	return (
		<button
			disabled={disable}
			className={`${buttonStyles.baggageInfoButton} gap-2 ${
				noBax ? buttonStyles.baggageInfoButtonYellow : null
			}  ${disable ? 'button-disbale' : null}`}
			onClick={onClick}
		>
			{noBax && (
				<ModalCheckIcon
					width={30}
					height={30}
					className="mr-2 rounded-sm absolute top-1 left-1"
				/>
			)}
			{disable ? (
				<DisabledLuggageIcon width={75} height={75} />
			) : (
				<LuggageIcon width={75} height={75} />
			)}
			{title}
		</button>
	);
};
