import { ReactComponent as ErrorIcon } from '../../assets/icons/xeu-error.svg';
import { ErrorBlurb } from '../../types';
import errorStyles from '../../styles/error/error.module.css';

export const ErrorBox = ({ error }: { error: ErrorBlurb }) => {
	return (
		<div className={errorStyles.errorBox}>
			<ErrorIcon />
			<p className={errorStyles.errorMessage}>{error.errorMessage}</p>
			<p className={errorStyles.actionMessage}>{error.actionMessage}</p>
		</div>
	);
};
