import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { API_PATHS } from '../../service/api';
import { ArrivalBeltStatus } from '../../types';
import { milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection } from '../../utils/timers';

enum actionTypes {
	getArrivalBeltStatus = 'arrivalBeltStatus/get',
	updateArrivalBeltStatus = 'updateArrivalBeltStatus/get',
}
let requestRunning: boolean = false;
export const getArrivalBeltStatus = createAsyncThunk(
	actionTypes.getArrivalBeltStatus,
	async (_, thunkAPI) => {
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<ArrivalBeltStatus>(
					API_PATHS.getArrivalBeltStatus + `?fullState=true`
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;
				thunkAPI.dispatch(updateArrivalBeltStatus(lastModifiedHeader));
				return response.data;
			} catch (error: any) {
				requestRunning = false;
				setTimeout(() => {
					thunkAPI.dispatch(getArrivalBeltStatus());
				}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
				throw error;
			}
		}
	}
);

export const updateArrivalBeltStatus = createAsyncThunk(
	actionTypes.updateArrivalBeltStatus,
	async (lastModified: string, { dispatch }) => {
		const headers = {
			'If-Modified-Since': lastModified, // Add If-Modified-Since header to query for new data
		};
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<ArrivalBeltStatus>(API_PATHS.getArrivalBeltStatus, {
					headers,
				});
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				if (response.status === 200) {
					dispatch(updateArrivalBeltStatus(lastModifiedHeader));
					return response.data;
				}
			} catch (error: any) {
				requestRunning = false;
				if (error && (!error.response || error.response.status >= 400)) {
					setTimeout(() => {
						dispatch(getArrivalBeltStatus());
					}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
					throw error;
				}
				if (error.response.status === 304) {
					dispatch(updateArrivalBeltStatus(lastModified));
					return;
				}
			}
		}
	}
);
