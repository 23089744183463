import { useEffect, useState } from 'react';

const DEFAULT_REFRESH_INTERVAL = 10000;

class RefreshOnNewBuildHelper {
    /**
     * Fetches index.html from the server, parses out the script tag via an XPath query,
     * and digs out the `src` attribute.
     * @returns 
     *  the `src` attribute of the bundle script tag or an empty string
     */
    static tryGetBundlePath = async (): Promise<{bundlePath: string, success: boolean}> => {
        const indexHtmlResponse = await fetch("/", {cache: "no-store"});
        const htmlString = await indexHtmlResponse.text();
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    
        const xPathResult = doc.evaluate("//head/script[1]", doc);
        const scriptTag = xPathResult.iterateNext();

        const bundlePath = (scriptTag as HTMLScriptElement)?.src ?? "";
        
        return {
            bundlePath: bundlePath,
            success: indexHtmlResponse.status === 200 && bundlePath !== ""
        }
    }

    static shouldForceRefresh = async (oldBundlePath: string): Promise<boolean> => {
        const bundlePathResponse = await this.tryGetBundlePath();
    
        return bundlePathResponse.success && oldBundlePath !== bundlePathResponse.bundlePath;
    }

    static forceReload = async () => {
        // Delete caches so a refresh fetches from the server
        const cacheKeys = await caches.keys();
        await Promise.all(cacheKeys.map(key => caches.delete(key)));

        window.location.reload();
    }
}

/**
 * useRefreshCheck Hook
 *
 * A custom React Hook for periodically checking if a hard refresh of the web application is recommended
 * based on the comparison of the current bundle path with the previous bundle path.
 * 
 * The bundle path is specifically the `src` attribute of the script tag in the document head.
 * In production this `src` element will be of the form `/static/js/main.[bundle-hash].js`, meaning it will be updated on each build. 
 * 
 * In local development this path will always be `bundle.js`, so there won't be any effect there.
 *
 * @returns {object} - An object with properties and functions to manage refresh checking:
 * @param shouldHardRefresh: A boolean indicating whether a hard refresh is recommended.
 * @param terminate: A function to stop the internal refresh-checking timer.
 */
const useRefreshCheck = (): {shouldHardRefresh: boolean, terminate: () => void; } => {
    const [oldBundlePath, setOldBundlePath] = useState<string>("");
    const [initialized, setIsInitialized] = useState<boolean>(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
    const [shouldHardRefresh, setShouldHardRefresh] = useState<boolean>(false);

    const refreshIntervalMilliseconds = window._env_.REACT_APP_REFRESH_CHECK_INTERVAL_MILLISECONDS ? Number(window._env_.REACT_APP_REFRESH_CHECK_INTERVAL_MILLISECONDS) : DEFAULT_REFRESH_INTERVAL; 

    const setInitialBundleUri = async () => {
        const {bundlePath} = await RefreshOnNewBuildHelper.tryGetBundlePath()
        setOldBundlePath(bundlePath);
        setIsInitialized(true);
    }

    useEffect(() => {
        setInitialBundleUri()
    }, [])

    useEffect(() => {
        if (!initialized || oldBundlePath === '')
            return;

        const id = setInterval(async () => {
            if (await RefreshOnNewBuildHelper.shouldForceRefresh(oldBundlePath))
                setShouldHardRefresh(true);
        }, refreshIntervalMilliseconds)
        setIntervalId(id);

        return () => clearInterval(id)
    }, [initialized, oldBundlePath])

    return {shouldHardRefresh, terminate: () => clearInterval(intervalId)}
}

export { useRefreshCheck }
export default RefreshOnNewBuildHelper;