import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
	interceptorIsReady: boolean;
};

const initialState: InitialState = {
	interceptorIsReady: false,
};

const apiConfigurationstateSlice = createSlice({
	name: 'apiConfigurationstate',
	initialState: initialState,
	reducers: {
		setInterceptorIsReady: (state) => {
			state.interceptorIsReady = true;
		},
	},
});

export default apiConfigurationstateSlice.reducer;

export const { setInterceptorIsReady } = apiConfigurationstateSlice.actions;
