import { useEffect, useState } from 'react';
// Components
import { Table } from '../components/table/index';
import { EntryFlightModal, ModalOverlay } from '../components/modal/index';
import { OutOfOrderBlock } from '../components/UI/index';
import EEUTableRow from '../components/table/EEU/EEUTableRow';
import { SlideUpList } from '../components/UI/SlideUpList';
import { TabBarNavigation } from '../components/table/EEUOEU/TabBarNavigation';
// Store
import { getBaggageEntryFlightState } from '../store/baggageEntryFlightState/actions';
import { setSelectedHandler } from '../store/baggageEntryFlightState/index';
// Hooks
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { handleFilteringOnSelectedHandlerAndSortingData } from '../hooks/useFilterOnHandler';
import useEventTracker from '../hooks/useEventTracker';
// Services
import { filterFlightsOnStatus, filterOnBaggageFactory } from '../service/filterService';
// Type
import { HandlerRefPair, FlightStatusObject, BaggageEntryFlight } from '../types';
import { BaggageFactory } from '../types/domainTypes';
// Utils
import { handlerRefPairForAllHandlers } from '../utils/handlers';
import { timeBeforeReturnEvent } from '../utils/timers';
import UrlParam from '../utils/urlParams';

export const EEUHome = () => {
	const dispatch = useAppDispatch();

	const openModal = useAppSelector((state) => state.modalStore.open);
	const baggageEntryFlightState = useAppSelector(
		(state) => state.baggageEntryFlightState.baggageEntryFlightState
	);
	const modalFlight = useAppSelector((state) => state.modalStore.flight);
	const error = useAppSelector((state) => state.baggageEntryFlightState.error);
	const interceptorIsReady = useAppSelector(
		(state) => state.apiConfigurationstate.interceptorIsReady
	);

	const selectedHandler = useAppSelector(
		(state) => state.baggageEntryFlightState.selectedHandler
	);
	const [flightStatuses, setFlightStatuses] = useState<FlightStatusObject>();

	useEffect(() => {
		// Only allow user to see anything on the screen, if both gate and baggageFactory has been added to the url
		const baggageFactory = UrlParam.baggageFactoryParamInUrl;
		const gate = UrlParam.gateParamInUrl;
		if (gate && baggageFactory && interceptorIsReady) {
			dispatch(getBaggageEntryFlightState());
		}
	}, [interceptorIsReady]);

	const [sortedData, setSortedData] = useState<FlightStatusObject>();

	// Everytime data from BE changes, filter and retrieve relevant flights according to current baggagefactory.
	// Then sort according to inBlock.
	// And updateFlightStatus
	useEffect(() => {
		const baggageFactory = UrlParam.baggageFactoryParamInUrl;
		if (baggageEntryFlightState !== undefined) {
			// Only show data relevant to the relevant baggageFactory
			const filteredOnBaggageFactory = filterOnBaggageFactory(
				baggageFactory as BaggageFactory,
				baggageEntryFlightState.data
			);
			// Sort data according to completed and "incoming" flights
			const flightStatusObject = filterFlightsOnStatus(filteredOnBaggageFactory);
			setFlightStatuses(flightStatusObject);
		}
	}, [baggageEntryFlightState]);

	// Everytime flightStatuses change, filter on selectedHandler.
	useEffect(() => {
		// filterOnHandler needs to happen each time flightStatuses is updated
		filterOnHandler(selectedHandler);
	}, [flightStatuses]);

	// Filter array of flights when user clicks on specific handler
	const filterOnHandler = (handlerRefPair: HandlerRefPair) => {
		dispatch(setSelectedHandler(handlerRefPair));
		const sortedAndFilteredFeed = handleFilteringOnSelectedHandlerAndSortingData(
			handlerRefPair,
			flightStatuses
		);
		setSortedData(sortedAndFilteredFeed);
	};

	const filterFlights = (handlerRefPair: HandlerRefPair) => {
		filterOnHandler(handlerRefPair);
	};

	// Handle redirect bacl to "All" filter, if screen is not touched for 30 seconds
	const secondsSinceLastClick = useEventTracker('click');
	const secondsSinceLastTouch = useEventTracker('touch');

	useEffect(() => {
		if (secondsSinceLastClick && secondsSinceLastClick >= timeBeforeReturnEvent) {
			dispatch(setSelectedHandler(handlerRefPairForAllHandlers));
		}
	}, [secondsSinceLastClick]);

	useEffect(() => {
		if (secondsSinceLastTouch && secondsSinceLastTouch >= timeBeforeReturnEvent) {
			dispatch(setSelectedHandler(handlerRefPairForAllHandlers));
		}
	}, [secondsSinceLastTouch]);

	return (
		<>
			{error ? (
				<div className="h-full w-full-important flex items-center justify-center">
					<OutOfOrderBlock />
				</div>
			) : (
				<>
					<div className="pr-5 pl-5 flex flex-col justtify-between pr-5 pl-5">
						<TabBarNavigation
							onClick={(handlerRefPair) => filterFlights(handlerRefPair)}
							classes="fixed w-[calc(100%-2.5rem)]"
						/>
						<section className="eeu-entry-flight-table mb-[17vh] mt-[9.5rem]">
							{sortedData?.openFlights && (
								<Table classes="rounded-xl">
									<tbody>
										{sortedData?.openFlights.map((flight) => (
											<EEUTableRow
												flight={flight as BaggageEntryFlight}
												key={flight.flightRef}
											/>
										))}
									</tbody>
								</Table>
							)}
						</section>
						{openModal && modalFlight && (
							<ModalOverlay>
								<EntryFlightModal flight={modalFlight as BaggageEntryFlight} />
							</ModalOverlay>
						)}
					</div>
					{sortedData?.completedFlights && (
						<SlideUpList flightStatuses={flightStatuses} />
					)}
				</>
			)}
		</>
	);
};
