import { BaggageEntryFlight } from '../../../types';
import { ReactComponent as WhiteCheckIcon } from './../../../assets/icons/xeu-white-check-stroke.svg';
import { ReactComponent as BlueCheckIcon } from './../../../assets/icons/xeu-blue-check-stroke.svg';
import { ReactComponent as BluePendingIcon } from './../../../assets/icons/xeu-pending.svg';
import { ReactComponent as WhitePendingIcon } from './../../../assets/icons/xeu-white-pending.svg';
import { ReactComponent as BlueBagIcon } from './../../../assets/icons/xeu-blue-bag.svg';
import { ReactComponent as WhiteBagIcon } from './../../../assets/icons/xeu-white-bag.svg';

import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { getBeltLabel } from '../../../service/beltDisplayNameService';

export const BeltStatusInformation = ({ flight }: { flight: BaggageEntryFlight }) => {
	const arrivalInfo = () => {
		if (flight.noArrivalBax) {
			return {
				icon: <WhiteCheckIcon width={15} />,
				text: 'No bags',
			};
		} else if (flight.arrivalActualLastBag || flight.noArrivalBax) {
			return {
				icon: <WhiteCheckIcon width={18} />,
				text: 'Completed',
			};
		} else if (flight.arrivalActualFirstBag) {
			return {
				icon: <WhiteBagIcon width={18} />,
				text: 'First bag',
			};
		} else if (flight.entryAtGate) {
			return {
				icon: <WhitePendingIcon width={18} />,
				text: '',
			};
		}
	};
	const transferInfo = () => {
		if (flight.noTransferBax) {
			return {
				icon: <BlueCheckIcon width={18} />,
				text: 'No bags',
			};
		} else if (flight.transferActualLastBag || flight.noTransferBax) {
			return {
				icon: <BlueCheckIcon width={18} />,
				text: 'Completed',
			};
		} else if (flight.transferActualFirstBag) {
			return {
				icon: <BlueBagIcon width={18} />,
				text: 'First bag',
			};
		} else if (flight.entryAtGate) {
			return {
				icon: <BluePendingIcon width={15} />,
				text: '',
			};
		}
	};

	const showInfoBox =
		((flight.arrivalActualLastBag || flight.noArrivalBax) &&
			(flight.transferActualLastBag || flight.noTransferBax)) ||
		flight.entryAtGate
			? true
			: false;

	return (
		<>
			{showInfoBox && (
				<div className={tableRowStyles.arrivalTranserInfoWrapper}>
					<div
						className={`${tableRowStyles.arrivalWrapper} ${tableRowStyles.arrivalTransferInfo}`}
					>
						<div className={tableRowStyles.arrivalTransferInfoIcon}>
							{arrivalInfo()?.icon}
						</div>
						<div className={tableRowStyles.arrivalTransferInfoTextWrapper}>
							<p className={`${tableRowStyles.arrivalTransferInfoText}`}>
								{getBeltLabel(flight.arrivalBeltName)}
								<span className="white-dot"></span>
								{flight.arrivalBeltUnloadDirection.charAt(0)}
							</p>
							<p className="text-lg">{arrivalInfo()?.text}</p>
						</div>
					</div>
					<div
						className={`${tableRowStyles.transferWrapper} ${tableRowStyles.arrivalTransferInfo}`}
					>
						<div className={tableRowStyles.arrivalTransferInfoIcon}>
							{transferInfo()?.icon}
						</div>
						<div className={tableRowStyles.arrivalTransferInfoTextWrapper}>
							<p className={`${tableRowStyles.arrivalTransferInfoText}`}>
								{getBeltLabel(flight.transferBeltName)}
								<span className="blue-dot"></span>
								{flight.transferBeltUnloadDirection.charAt(0)}
							</p>
							<p className="text-s">{transferInfo()?.text}</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
