import { useState, useEffect } from 'react';
import headerStyles from '../../styles/Header.module.css';
import { retrieveClockTimestamp } from '../../service/timestampService';

export const Clock = () => {
	const [date, setDate] = useState<Date>(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDate(new Date());
		}, 1000);

		// Clean up the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	const timestamp = retrieveClockTimestamp(date.toISOString());
	return <div className={headerStyles.clock}>{timestamp}</div>;
};
