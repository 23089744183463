import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { API_PATHS } from '../../service/api';
import { BaggageArrivalFlightState } from '../../types';
import { milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection } from '../../utils/timers';

enum actionTypes {
	getBaggageArrivalFlightState = 'baggageArrivalFlightState/get',
	updateBaggageArrivalFlightState = 'baggageArrivalFlightState/update/get',
}

let requestRunning: boolean = false;

export const getBaggageArrivalFlightState = createAsyncThunk(
	actionTypes.getBaggageArrivalFlightState,
	async (_, thunkAPI) => {
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<BaggageArrivalFlightState>(
					API_PATHS.getBaggageArrivalFlightState + `?fullState=true`
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				thunkAPI.dispatch(updateBaggageArrivalFlightState(lastModifiedHeader));
				return response.data;
			} catch (error: any) {
				requestRunning = false;
				setTimeout(() => {
					thunkAPI.dispatch(getBaggageArrivalFlightState());
				}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
				throw error;
			}
		}
	}
);

export const updateBaggageArrivalFlightState = createAsyncThunk(
	actionTypes.updateBaggageArrivalFlightState,
	async (lastModified: string, { dispatch }) => {
		const headers = {
			'If-Modified-Since': lastModified, // Add If-Modified-Since header to query for new data
		};
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<BaggageArrivalFlightState>(
					API_PATHS.getBaggageArrivalFlightState,
					{
						headers,
					}
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				if (response.status === 200) {
					dispatch(updateBaggageArrivalFlightState(lastModifiedHeader));
					return response.data;
				}
			} catch (error: any) {
				requestRunning = false;
				if (error && (!error.response || error.response.status >= 400)) {
					setTimeout(() => {
						dispatch(getBaggageArrivalFlightState());
					}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
					throw error;
				}
				if (error.response.status === 304) {
					dispatch(updateBaggageArrivalFlightState(lastModified));
					return;
				}
			}
		}
	}
);
