import wrapperStyles from '../../styles/UI/Wrapper.module.css';

export const BlueRoundedWrapper = ({
	children,
	classes,
}: {
	children: JSX.Element | JSX.Element[];
	classes?: string;
}) => {
	const classList = `${wrapperStyles.blueRoundedWrapper} ${classes ? classes : ''}`;
	return <div className={classList}>{children}</div>;
};
