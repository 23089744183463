import { ArrivalBeltStatusDataObject } from '../types';

class beltStatusService {
	static filterAndExtractBaltStateMessagesBasedOnCurrentBelt = (
		belt: string,
		beltStatus: ArrivalBeltStatusDataObject[]
	) => {
		const beltStatusObject = beltStatus.find(
			(statusObject) => statusObject.baggageBeltName === belt
		);

		return this.extractStatusNames(beltStatusObject!);
	};

	static extractStatusNames = (beltStatusObject: ArrivalBeltStatusDataObject) => {
		const statusLabelArray: string[] = [];
		
		let isOutOfServicePushed: boolean = false

		for (const key in beltStatusObject) {
			if (beltStatusObject[key as keyof ArrivalBeltStatusDataObject] === true) {
				switch (key) {
					case 'blockedByConveyorSystemController':
					case 'blockedByOperator':
					case 'destinationJammed':
					case 'faulted':
						if (!isOutOfServicePushed) {
							isOutOfServicePushed = true;
							statusLabelArray.push('Belt temporarily out of service.');
						}
						break;
					case 'destinationFull':
						statusLabelArray.push('Belt full.');
						break;
					default:
						break;
				}
			}
		}

		return statusLabelArray;
	};
}

export default beltStatusService;