export const Table = ({
  children,
  classes,
}: {
  children: JSX.Element | JSX.Element[];
  classes?: string;
}) => {
  const classList = `table ${classes ? classes : ""}`;

  return <table className={classList}>{children}</table>;
};
