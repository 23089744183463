export const FlexColumn = ({
	children,
	classes,
}: {
	children: JSX.Element | JSX.Element[];
	classes?: string;
}) => {
	const classList = `flex justify-between items-center ${classes ? classes : ''}`;
	return <div className={classList}>{children}</div>;
};
