import axios, { AxiosError } from 'axios';
import { ErrorData } from '../types';
import UrlParam from '../utils/urlParams';
import RefreshOnNewBuildHelper from '../utils/refreshOnNewBuild';

export const baseURL = window._env_.REACT_APP_API_URL;
export enum API_PATHS {
	getBaggageEntryFlightState = '/baggage-entry/state',
	postCheckInResource = '/baggage-entry/check-in',
	getBaggageArrivalFlightState = '/baggage-arrival/state',
	getBaggageTransferFlightState = '/baggage-transfer/state',
	postFirstBag = '/baggage/first-bag',
	postLastBag = '/baggage/last-bag',
	postNoBax = '/baggage/no-bax',
	reallocate = '/baggage/reallocate',
	getBeltInfo = 'someSortOfRest',
	baggageFactory = '/baggage-factory/state',
	reallocationState = 'baggage-reallocation/state',
	baggageOddSize = '/baggage-odd/first-bag',
	getArrivalBeltStatus = '/baggage-belt/state',
}

const api = axios.create({
	baseURL,
});

export default api;

api.interceptors.response.use(
	(res) => {
		return Promise.resolve(res);
	},
	(err: AxiosError) => {
		// THIS IS A NOTE WRITTEN IN BAGSTAGE GUI - But it is also relevant here, so I will leave it
		// Note: This does not work all that well, when redux is mixed in, and is dispatching the call as a wrapper.
		// The message is by default a genering message like "Error: Request failed with status code 400", and then only the stack trace is available (not the actual data).
		// When we later catch this error, we will get the Error object from Node rather than the extended AxiosError object from axios.
		// To come around this, we are overwriting the message, if the data is a string, as we then assume it is the error message.
		// Redux does also seem to modify the request shown in the browsers dev tool.
		// If you want to handle the error in greater details with error data from the server, then it is recommended to use axios directly instead of going through the dispatch.
		// An example for this is in UploadInitionalPlanButton.tsx (Greenforce project).
		if (err.response?.data && typeof err.response.data === 'string') {
			err.message = err.response.data;
		} else if (err.response?.data) {
			const data = err.response.data as ErrorData;
			err.message = data.message;
		}

		return Promise.reject(err);
	}
);

/**
 * An interceptor to cache-bust in the case of getting a 401 on a request.
 * This is to handle switching security status on the staging environment, where going from disabled -> enabled 
 * will cause the application to get stuck in a state where it hasn't created the keycloak object
 */
export function addSecurityDisabledResponseInterceptor() {
	api.interceptors.response.use(
		(response) => Promise.resolve(response),
		(error) => {
			if (error.response && error.response.status === 401) {
				RefreshOnNewBuildHelper.forceReload();
			}

			return Promise.reject(error)
		}
	)
}

export function addKeycloakInterceptorWithKeycloakInstance(keycloakObject: any) {
	api.interceptors.request.use(
		(config) => requestInterceptorTokenHandler(config, keycloakObject),

		(request) => {
			return request;
		}
	);
}

/* The updateToken function is a keycloak function that checks if the token expires within any given 
timeframe (in seconds) - and if so, updates the token. */
const requestInterceptorTokenHandler = async (config: any, keycloakObject: any) => {
	try {
		const MIN_VALIDITY_SECS = 10;
		await keycloakObject.updateToken(MIN_VALIDITY_SECS);
		const token = keycloakObject.token;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	} catch (error) {
		keycloakObject.logout({
			redirectUri: UrlParam.redirectUrl,
		});
	}
	return config;
};
