import buttonStyles from '../../styles/UI/Button.module.css';

export const StandardButton = ({
	onClick,
	title,
	noBax,
	icon,
	classes,
}: {
	onClick: () => void;
	title: string;
	noBax?: boolean;
	icon?: React.ReactNode;
	classes?: string;
}) => {
	const classList = `${buttonStyles.standardButton} ${classes ? classes : null}`;
	return (
		<>
			{noBax ? (
				<div className="mr-[1rem] no-bax-label">
					{icon}
					{title}
				</div>
			) : (
				<button onClick={onClick} className={classList}>
					{icon}
					{title}
				</button>
			)}
		</>
	);
};
