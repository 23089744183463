import {
	BaggageEntryFlight,
	HandlerRefPair,
	BaggageFactory,
	BaggageArrivalFlight,
	BaggageTransferFlight,
	TransferFlightStatusObject,
	ArrivalBeltStatusDataObject,
} from '../types';
import { FlightStatusObject, ArrivalFlightStatusObject } from '../types';

export const filterDataAccordingToHandler = (
	baggageEntryFlights: FlightStatusObject,
	handlerRefPair: HandlerRefPair
): FlightStatusObject => {
	const filteredOpenEntryFlights = baggageEntryFlights.openFlights.filter((flight) => {
		return flight.handlerRef === handlerRefPair.abbreviation;
	});
	const filteredCompletedEntryFlights = baggageEntryFlights.completedFlights.filter((flight) => {
		return flight.handlerRef === handlerRefPair.abbreviation;
	});
	return {
		openFlights: filteredOpenEntryFlights,
		completedFlights: filteredCompletedEntryFlights,
	};
};

export const filterListAccordingToHandler = (
	flights: BaggageEntryFlight[],
	handlerRefPair: HandlerRefPair
): BaggageEntryFlight[] => {
	const filteredFlights = flights.filter((flight) => {
		return flight.handlerRef === handlerRefPair.abbreviation;
	});

	return filteredFlights;
};

// Used to check whether or not it has been x sec. since baggage was completed.
const checkIfToKeepOnOpenList = (date: any, timespan: number) => {
	// @ts-ignore
	const timeDifference = new Date() - new Date(date);
	return timeDifference < timespan ? true : false;
};

// Filter EntryFlights according to open and completed flights.
// Completed flight should be moved to completed list 15 seconds after setting lastBag or noBax.
export const filterFlightsOnStatus = (
	baggageEntryFlights: BaggageEntryFlight[]
): FlightStatusObject => {
	const completedEntryFlights = baggageEntryFlights.filter(
		(x: BaggageEntryFlight) =>
			x.transferActualLastBag &&
			!checkIfToKeepOnOpenList(x.transferActualLastBag, 15000) &&
			x.arrivalActualLastBag &&
			!checkIfToKeepOnOpenList(x.arrivalActualLastBag, 15000)
	);
	const openEntryFlights = baggageEntryFlights.filter(
		(x: BaggageEntryFlight) =>
			!(
				x.transferActualLastBag && !checkIfToKeepOnOpenList(x.transferActualLastBag, 15000)
			) ||
			!(x.arrivalActualLastBag && !checkIfToKeepOnOpenList(x.arrivalActualLastBag, 15000))
	);
	return {
		openFlights: openEntryFlights,
		completedFlights: completedEntryFlights,
	};
};

// Filter OUEFlights according to open and completed flights.
// Flight entries should be moved to completed list if it is 30 minutes past lastbag, or
// if flight has firstOddSizeBag and x seconds has passed.
export const filterOEUFlightsOnStatus = (
	baggageEntryFlights: BaggageEntryFlight[]
): FlightStatusObject => {
	const completedEntryFlights = baggageEntryFlights.filter(
		(x: BaggageEntryFlight) =>
			!checkIfToKeepOnOpenList(x.arrivalActualLastBag, 60000 * 30) ||
			(x.firstOddSizeBag && !checkIfToKeepOnOpenList(x.firstOddSizeBag, 15000))
	);
	const openEntryFlights = baggageEntryFlights.filter(
		(x: BaggageEntryFlight) =>
			(x.firstOddSizeBag && checkIfToKeepOnOpenList(x.firstOddSizeBag, 15000)) ||
			(!x.firstOddSizeBag &&
				(!x.arrivalActualLastBag ||
					checkIfToKeepOnOpenList(x.arrivalActualLastBag, 60000 * 30)))
	);

	return {
		openFlights: openEntryFlights,
		completedFlights: completedEntryFlights,
	};
};

export const filterFlightsOnArrivalStatus = (
	baggageEntryFlights: BaggageArrivalFlight[]
): ArrivalFlightStatusObject => {
	const completedArrivalFlights = baggageEntryFlights.filter(
		(x: BaggageArrivalFlight) =>
			x.isGhost || (x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000))
	);
	const openArrivalFlightsCheckedIn = baggageEntryFlights.filter(
		(x: BaggageArrivalFlight) =>
			!x.isGhost &&
			x.entryAtGate &&
			!(x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000))
	);
	const openArrivalFlightsNotCheckedIn = baggageEntryFlights.filter(
		(x: BaggageArrivalFlight) =>
			!x.isGhost &&
			!x.entryAtGate &&
			!(x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000))
	);
	return {
		openFlightsCheckedIn: openArrivalFlightsCheckedIn,
		openFlightsNotCheckedIn: openArrivalFlightsNotCheckedIn,
		completedFlights: completedArrivalFlights,
	};
};

export const filterFlightsOnTransferStatus = (
	baggageEntryFlights: BaggageTransferFlight[]
): TransferFlightStatusObject => {
	const completedTransferFlights = baggageEntryFlights.filter(
		(x: BaggageTransferFlight) =>
			x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000)
	);

	const openTransferFlightsCheckedIn = baggageEntryFlights.filter(
		(x: BaggageTransferFlight) =>
			x.entryAtGate && !(x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000))
	);
	const openTransferFlightsNotCheckedIn = baggageEntryFlights.filter(
		(x: BaggageTransferFlight) =>
			!x.entryAtGate && !(x.actualLastBag && !checkIfToKeepOnOpenList(x.actualLastBag, 15000))
	);
	return {
		openFlightsCheckedIn: openTransferFlightsCheckedIn,
		openFlightsNotCheckedIn: openTransferFlightsNotCheckedIn,
		completedFlights: completedTransferFlights,
	};
};

export const filterOnBaggageFactory = (
	baggageFactory: BaggageFactory,
	baggageEntryFlights: BaggageEntryFlight[]
) => {
	const filteredOnBaggageFactory = baggageEntryFlights.filter((flight) => {
		return (
			flight.arrivalBaggageFactory === baggageFactory ||
			flight.transferBaggageFactory === baggageFactory
		);
	});
	return filteredOnBaggageFactory;
};

export const filterFlightsOnBelt = (
	// To do - create enum
	beltName: string,
	baggageFlights: BaggageArrivalFlight[] | BaggageTransferFlight[]
) => {
	const filteredOnBeltName = baggageFlights.filter((flight) => {
		return flight.beltName === beltName;
	});
	return filteredOnBeltName;
};
