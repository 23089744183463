import { TableTitle } from '../index';
import { Timestamp } from '../../UI/index';
import { ReactComponent as InBlockIcon } from '../../../assets/icons/xeu-blue-b.svg';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../store/modalStore';

import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { BaggageEntryFlight } from '../../../types';
import { useEffect } from 'react';
import { BeltStatusInformation } from '../EEUOEU/BeltStatusInformation';
import { useAppSelector } from '../../../store/hooks';
import { resetFlightToAnimate } from '../../../store/animation';

export default function EEUTableRow({ flight }: { flight: BaggageEntryFlight }) {
	const dispatch = useDispatch();
	// @ts-ignore
	const modalOpen = useSelector((state) => state.modalStore.open);
	const flightToAnimate = useAppSelector((state) => state.animationStore.flightRef);

	const toggleModal = () => {
		dispatch(openModal(flight));
	};

	useEffect(() => {
		if (flightToAnimate && flight.flightRef) {
			setTimeout(() => {
				dispatch(resetFlightToAnimate());
			}, 2000);
		}
	}, [flightToAnimate]);

	// Prevent scroll, when modal is open.
	useEffect(() => {
		const body = document.querySelector('body');
		if (body && modalOpen) {
			body.classList.remove('overflow-y-scroll');
			body.classList.add('overflow-y-hidden');
		} else if (body && !modalOpen) {
			body.classList.add('overflow-y-scroll');
			body.classList.remove('overflow-y-hidden');
		}
	}, [modalOpen]);

	return (
		<tr
			className={`${tableRowStyles.tableRow} ${
				tableRowStyles.eeuTableRow
			} table-font w-full-important flex items-center justify-center h-[6.5rem] ${
				flightToAnimate === flight.flightRef
					? tableRowStyles.animateXScalingOnReturnBehaviour
					: null
			}`}
			onClick={toggleModal}
		>
			<td className={`h-full flex ${tableRowStyles.tableRowBlock} w-[50%]`}>
				<TableTitle
					title={`${flight.airlineIdentifier} ${flight.flightNumber}`}
					classes="w-[250px]"
				/>
				<p className="font-secondary text-[28px]">{flight.origins}</p>
				<div className="grey-dot m-[1rem]"></div>
				<InBlockIcon width={23} height={23} className="mr-2" />
				<Timestamp date={flight.inBlock} classes="text-[28px]" />
			</td>
			<td
				className={`w-full h-100 ${tableRowStyles.tableRowBlock} flex items-center justify-end `}
			>
				<BeltStatusInformation flight={flight} />
			</td>
		</tr>
	);
}
