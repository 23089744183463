import { createSlice } from '@reduxjs/toolkit';
import { BaggageTransferFlightState } from '../../types';
import { HandlerRefPair } from '../../types';
import { getBaggageTransferFlightState, updateBaggageTransferFlightState } from './action';

export interface BaggageTransferStateSlice {
	error: boolean;
	baggageTransferFlightState: BaggageTransferFlightState | undefined;
	lastModified?: string;
	selectedHandler?: HandlerRefPair;
}

const initialState: BaggageTransferStateSlice = {
	error: false,
	baggageTransferFlightState: undefined,
};

const baggageTransferFlightStateSlice = createSlice({
	name: 'baggage-transfer-state',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBaggageTransferFlightState.rejected, (state) => {
			state.error = true;
			state.baggageTransferFlightState = undefined;
		});
		builder.addCase(getBaggageTransferFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null) {
				state.baggageTransferFlightState = action.payload;
			} else {
				state.baggageTransferFlightState = undefined;
			}
		});
		builder.addCase(updateBaggageTransferFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null && action.payload !== undefined) {
				state.baggageTransferFlightState = action.payload;
			}
		});
		builder.addCase(updateBaggageTransferFlightState.rejected, (state, action) => {
			state.error = true;
			state.baggageTransferFlightState = undefined;
		});
	},
});

export default baggageTransferFlightStateSlice.reducer;
