import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
	flightRef?: string;
};

const initialState: InitialState = {};

const animationSlice = createSlice({
	name: 'animation',
	initialState: initialState,
	reducers: {
		setFlightToAnimate: (state, action) => {
			state.flightRef = action.payload;
		},
		resetFlightToAnimate: (state) => {
			state.flightRef = undefined;
		},
	},
});

export default animationSlice.reducer;

export const { setFlightToAnimate, resetFlightToAnimate } = animationSlice.actions;
