import { createSlice } from '@reduxjs/toolkit';
import { BaggageReallocationState } from '../../types';
import { getBaggageReallocationState, updateBaggageReallocationState } from './action';

export interface BaggageArrivalStateSlice {
	error: boolean;
	baggageReaalocationState: BaggageReallocationState | undefined;
}

const initialState: BaggageArrivalStateSlice = {
	error: false,
	baggageReaalocationState: undefined,
};

const baggageReallocationStateSlice = createSlice({
	name: 'baggage-reallocation-state',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBaggageReallocationState.rejected, (state, action) => {
			state.error = true;
			state.baggageReaalocationState = undefined;
		});
		builder.addCase(getBaggageReallocationState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null) {
				state.baggageReaalocationState = action.payload;
			} else {
				state.baggageReaalocationState = undefined;
			}
		});
		builder.addCase(updateBaggageReallocationState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null && action.payload !== undefined) {
				state.baggageReaalocationState = action.payload;
			}
		});
		builder.addCase(updateBaggageReallocationState.rejected, (state) => {
			state.error = true;
			state.baggageReaalocationState = undefined;
		});
	},
});

export default baggageReallocationStateSlice.reducer;
