import React from 'react';
import modalStyles from '../../styles/modal/Modal.module.css';
import { useAppDispatch } from '../../store/hooks';
import { closeModal } from '../../store/modalStore';

export const ModalOverlay = ({
	onClick,
	children,
}: {
	onClick?: () => void;
	children?: React.ReactNode;
}) => {
	const dispatch = useAppDispatch();
	const closeModalOnClick = () => {
		dispatch(closeModal());
		if (onClick) {
			onClick();
		}
	};
	return (
		<section
			className={modalStyles.modalOverlay}
			id="modal-overlay"
			onClick={closeModalOnClick}
		>
			{children}
		</section>
	);
};
