export const retrieveClockTimestamp = (dateISOString: string) => {
	const date = new Date(dateISOString);
	const formattedMinutes =
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
	const formattedHours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
	const formattedSeconds =
		date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const retrieveTimestamp = (dateISOString: string) => {
	const date = new Date(dateISOString);
	const formattedMinutes =
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
	const formattedHours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;

	return `${formattedHours}:${formattedMinutes}`;
};

export const retrieveTimeSpan = (start: Date, end: Date) => {
	// @ts-ignore
	const timeDifference = new Date(end) - new Date(start);
	const minutesDifference = Math.floor(timeDifference / (1000 * 60));
	const secondsDifference = Math.floor((timeDifference / 1000) % 60);

	const prettierSeconds =
		secondsDifference < 10 ? `0${secondsDifference}` : `${secondsDifference}`;
	const prettierMinutes =
		minutesDifference < 10 ? `0${minutesDifference}` : `${minutesDifference}`;

	return `${prettierMinutes}:${prettierSeconds}`;
};
