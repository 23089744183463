import { useState, useEffect, useRef } from 'react';
import Keycloak from 'keycloak-js';

const useAuth = () => {
	const securityDisabled: boolean = JSON.parse(window._env_.REACT_APP_DISABLE_SECURITY ?? false);
	const isRun = useRef(false);
	const [isLogin, setLogin] = useState<boolean>(false);
	const [kctoken, setKctoken] = useState<string>();
	const [keycloakObject, setKeycloakObject] = useState<any>();


	// initialising keycloak
	useEffect(() => {
		// keycloak specific.
		if (isRun.current) return;
		// Do nothing if security is disabled
		if (securityDisabled) return;

		isRun.current = true;

		const client = new Keycloak({
			url: window._env_.REACT_APP_KC_URL,
			realm: window._env_.REACT_APP_KC_REALM,
			clientId: window._env_.REACT_APP_KC_CLIENT_ID
		});

		client
			.init({
				onLoad: 'login-required',
			})
			.then((res) => {
				setLogin(res);
				setKctoken(client.token);
				setKeycloakObject(client);
			});
	}, [keycloakObject]);

	return securityDisabled ? [true, keycloakObject] : [isLogin, keycloakObject];
};

export default useAuth;
