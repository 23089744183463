import { BaggageArrivalFlight, BaggageTransferFlight } from '../types';

export function getBackgroundColorForAEUTEUTableRows(
	flight: BaggageArrivalFlight | BaggageTransferFlight
) {
	if ((flight.actualFirstBag && flight.actualLastBag) || flight.noBax) {
		return 'bg-green-CPH-10-important';
	} else if (flight.actualFirstBag) {
		return 'bg-yellow-CPH-10-important';
	} else return 'bg-white';
}
