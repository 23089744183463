import { configureStore, combineReducers } from '@reduxjs/toolkit';
import modalSlice from './modalStore';
import apiConfigurationstate from './apiConfigurationstate';
import baggageEntryFlightStateSlice from './baggageEntryFlightState';
import pathSlice from './path/index';
import beltSlice from './belt/index';
import baggageArrivalFlightStateSlice from './baggageArrivalFlightState';
import baggageTransferFlightStateSlice from './baggageTransferFlightState';
import baggageReallocationStateSlice from './BaggageReallocationState';
import animationSlice from './animation';

const store = configureStore({
	reducer: combineReducers({
		modalStore: modalSlice,
		baggageEntryFlightState: baggageEntryFlightStateSlice,
		pathStore: pathSlice,
		beltStore: beltSlice,
		baggageArrivalFlightState: baggageArrivalFlightStateSlice,
		baggageTransferFlightState: baggageTransferFlightStateSlice,
		baggageReallocationStateStore: baggageReallocationStateSlice,
		animationStore: animationSlice,
		apiConfigurationstate: apiConfigurationstate,
	}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
