import React from 'react';

// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { BaggageArrivalFlight, BaggageTransferFlight } from '../../../types';
import { TableTitle } from '../TableTitle';

const TitleAndOriginTableBlock = ({
	flight,
}: {
	flight: BaggageArrivalFlight | BaggageTransferFlight;
}) => {
	return (
		<>
			<div className={`h-100 flex justify-between ${tableRowStyles.tableRowBlock} w-[16rem]`}>
				<TableTitle
					classes="w-[250px]"
					title={`${flight.airlineIdentifier} ${flight.flightNumber}`}
				/>
			</div>
			<p className="font-secondary text-[32px]">{flight.origins}</p>
		</>
	);
};

export default TitleAndOriginTableBlock;
