import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { API_PATHS } from '../../service/api';
import { BaggageEntryFlightState } from '../../types';
import { milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection } from '../../utils/timers';

enum actionTypes {
	getBaggageEntryFlightState = 'baggageEntryFlightState/get',
	updateBaggageEntryFlightState = 'baggageEntryFlightState/update/get',
}

let requestRunning: boolean = false;

export const getBaggageEntryFlightState = createAsyncThunk(
	actionTypes.getBaggageEntryFlightState,
	async (_, thunkAPI) => {
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<BaggageEntryFlightState>(
					API_PATHS.getBaggageEntryFlightState + `?fullState=true`
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				thunkAPI.dispatch(updateBaggageEntryFlightState(lastModifiedHeader));
				return response.data;
			} catch (error: any) {
				requestRunning = false;
				setTimeout(() => {
					thunkAPI.dispatch(getBaggageEntryFlightState());
				}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
				throw error;
			}
		}
	}
);

export const updateBaggageEntryFlightState = createAsyncThunk(
	actionTypes.updateBaggageEntryFlightState,
	async (lastModified: string, { dispatch }) => {
		const headers = {
			'If-Modified-Since': lastModified, // Add If-Modified-Since header to query for new data
		};
		if (!requestRunning) {
			requestRunning = true;
			try {
				const response = await api.get<BaggageEntryFlightState>(
					API_PATHS.getBaggageEntryFlightState,
					{
						headers,
					}
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				if (response.status === 200) {
					dispatch(updateBaggageEntryFlightState(lastModifiedHeader));
					return response.data;
				}
			} catch (error: any) {
				requestRunning = false;
				if (error && (!error.response || error.response.status >= 400)) {
					setTimeout(() => {
						dispatch(getBaggageEntryFlightState());
					}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
					throw error;
				}

				if (error.response.status === 304) {
					dispatch(updateBaggageEntryFlightState(lastModified));
					return;
				}
			}
		}
	}
);
