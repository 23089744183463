import React from 'react';
import { filterDataAccordingToHandler } from '../service/filterService';
import { sortFlights } from '../service/sortingService';
import { FlightStatusObject, HandlerRefPair } from '../types';
import { handlerRefPairForAllHandlers } from '../utils/handlers';

// Filter array of flights when user clicks on specific handler
// And sort it afterwards.
export const handleFilteringOnSelectedHandlerAndSortingData = (
	handlerRefPair: HandlerRefPair,
	flightStatusObject: FlightStatusObject | undefined
) => {
	if (
		flightStatusObject !== undefined &&
		handlerRefPair.handlerName !== handlerRefPairForAllHandlers.handlerName
	) {
		const filteredFlights = filterDataAccordingToHandler(flightStatusObject, handlerRefPair);
		return sortFlights(filteredFlights);
	} else if (flightStatusObject !== undefined) {
		return sortFlights(flightStatusObject);
	}
};
