import React, { useEffect, useState } from 'react';

const useEventTracker = (eventName: string) => {
	const [lastEventTime, setLastEventTime] = useState<number>();
	const [secondsPastLastEvent, setSecondsPastLastEvent] = useState<number>();
	const handleEvent = () => {
		setLastEventTime(Date.now());
	};

	useEffect(() => {
		const eventHandler = () => {
			handleEvent();
		};

		document.addEventListener(eventName, eventHandler);

		return () => {
			document.removeEventListener(eventName, eventHandler);
		};
	}, [eventName]);

	useEffect(() => {
		if (lastEventTime) {
			const intervalId = setInterval(() => {
				const elapsedTimeInMilliseconds = Date.now() - lastEventTime;
				const seconds = (elapsedTimeInMilliseconds % 60000) / 1000;
				setSecondsPastLastEvent(seconds);
			}, 1000); // Update every second

			return () => clearInterval(intervalId);
		}
	}, [lastEventTime, eventName]);

	return secondsPastLastEvent;
};

export default useEventTracker;
