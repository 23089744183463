import { BaggageEntryFlight, BaggageTransferFlight } from '../../types';
import { Timestamp, ModalTitle } from '../UI';
import { ReactComponent as InBlockIcon } from '../../assets/icons/xeu-blue-b.svg';

export const ModalHeaderBlock = ({
	flight,
}: {
	flight: BaggageEntryFlight | BaggageTransferFlight;
}) => {
	return (
		<div className="flex flex-col items-center justify-center mb-5">
			<ModalTitle
				title={`${flight.airlineIdentifier} ${flight.flightNumber}`}
				classes="mb-5 text-3xl-important"
			/>
			<div className="flex items-center justify-center gap-2">
				<p className="text-[2rem]">{flight.origins} </p>
				<div className="grey-dot m-[0.5rem]"></div>
				<InBlockIcon height={25} width={25} />
				<Timestamp date={flight.inBlock} classes="text-[2rem]" />
			</div>
		</div>
	);
};
