import React from 'react';
import { Timestamp } from '../../UI';
import { ReactComponent as InBlockIcon } from '../../../assets/icons/xeu-blue-b.svg';
// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { BaggageArrivalFlight, BaggageTransferFlight } from '../../../types';

const InBlockTableBlock = ({
	flight,
}: {
	flight: BaggageArrivalFlight | BaggageTransferFlight;
}) => {
	return (
		<div
			className={`h-100  ${tableRowStyles.tableRowBlock} w-131px] justify-end items-center flex`}
		>
			<InBlockIcon width={23} height={23} />
			<Timestamp
				classes={`time-tracker-timestamp ml-1 p-1 ${tableRowStyles.aeuTeuTableRowTimestamp}`}
				date={flight.inBlock}
			/>
		</div>
	);
};

export default InBlockTableBlock;
