import { useReducer, useState } from 'react';
import { BaggageEntryFlight, CheckInStatus, ErrorBlurb } from '../../types';
// Components
import { ModalButton, BlueRoundedWrapper, FlexColumn, ErrorBox } from '../UI/index';
import { ModalBaggageInfo, ModalBaggageButton } from './index';
import { ReactComponent as CheckIcon } from './../../assets/icons/xeu-white-check.svg';
import { ReactComponent as CancelIcon } from './../../assets/icons/xeu-white-cancel.svg';
// Services
import api, { API_PATHS } from '../../service/api';
// Store
import { useAppDispatch } from '../../store/hooks';
import { closeModal } from '../../store/modalStore';
import { setCurrentCheckInData } from '../../store/baggageEntryFlightState';
// Styles
import modalStyles from '../../styles/modal/Modal.module.css';
import { ModalHeaderBlock } from './ModalHeaderBlock';
// Other
import { setFlightToAnimate } from '../../store/animation';
import UrlParam from '../../utils/urlParams';

// ActionType for useReducer
type ActionType = 'NO_TRANSFER_BAX' | 'NO_ARRIVAL_BAX';

export const EntryFlightModal = ({ flight }: { flight: BaggageEntryFlight }) => {
	const storeDispatch = useAppDispatch();
	const gate = UrlParam.gateParamInUrl!;
	const baggageFactory = UrlParam.baggageFactoryParamInUrl!;
	const handleCloseModalEvent = () => {
		storeDispatch(closeModal());
	};

	const handleCheckInEvent = async () => {
		setErrorBlurb(null);
		try {
			const response = await api.post(API_PATHS.postCheckInResource, state);
			if (response.status === 202 || response.status === 200) {
				// If respose 202, update store data to represent the current state,
				// before new response is ready from getEntryFlight request.
				storeDispatch(setCurrentCheckInData(state));
				storeDispatch(setFlightToAnimate(state.flightRef));
			}
			storeDispatch(closeModal());
		} catch (error) {
			setErrorBlurb({
				errorMessage: 'Something went wrong.',
				actionMessage: 'Close modal and try again.',
			});
		}
	};

	const [errorBlurb, setErrorBlurb] = useState<ErrorBlurb | null>();

	const initialState: CheckInStatus = {
		flightRef: flight.flightRef,
		noArrivalBax: flight.noArrivalBax,
		noTransferBax: flight.noTransferBax,
		gate: gate,
		baggageFactory: baggageFactory,
	};

	const reducer = (state: CheckInStatus, action: { type: ActionType }): CheckInStatus => {
		switch (action.type) {
			case 'NO_ARRIVAL_BAX':
				return { ...state, noArrivalBax: !state.noArrivalBax };
			case 'NO_TRANSFER_BAX':
				return { ...state, noTransferBax: !state.noTransferBax };
			default:
				return state;
		}
	};

	// use useReducer to manage the collected state, used for POST body
	const [state, dispatch] = useReducer(reducer, initialState);

	const flightHasArrivalActualFirstOnly =
		flight.arrivalActualFirstBag &&
		!(flight.arrivalActualFirstBag === flight.arrivalActualLastBag && !flight.noArrivalBax);

	const flightHasTransferActualFirstOnly =
		flight.transferActualFirstBag &&
		!(flight.transferActualFirstBag === flight.transferActualLastBag && flight.noTransferBax);

	const checkIfArrivalNoBaxAllowed = () => {
		if (flightHasArrivalActualFirstOnly) {
			return true;
		} else return false;
	};

	const checkIfTransferNoBaxAllowed = () => {
		if (flightHasTransferActualFirstOnly) {
			return true;
		} else return false;
	};

	return (
		<article
			className={modalStyles.modal}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<ModalHeaderBlock flight={flight} />
			{errorBlurb ? (
				errorBlurb && <ErrorBox error={errorBlurb} />
			) : (
				<>
					<ModalBaggageInfo flight={flight}></ModalBaggageInfo>
					<BlueRoundedWrapper classes="mt-8">
						<ModalBaggageButton
							disable={checkIfArrivalNoBaxAllowed()}
							title={
								flightHasArrivalActualFirstOnly
									? 'CPH first bag is set'
									: 'Flight has no CPH bags'
							}
							noBax={state.noArrivalBax}
							onClick={() => dispatch({ type: 'NO_ARRIVAL_BAX' })}
						/>
						<ModalBaggageButton
							disable={checkIfTransferNoBaxAllowed()}
							title={
								flightHasTransferActualFirstOnly
									? 'Transfer first bag is set'
									: 'Flight has no transfer bags'
							}
							noBax={state.noTransferBax}
							onClick={() => dispatch({ type: 'NO_TRANSFER_BAX' })}
						/>
					</BlueRoundedWrapper>
				</>
			)}

			<FlexColumn>
				<ModalButton
					title="Cancel"
					classes="bg-red-CPH mr-6"
					callBackFunction={handleCloseModalEvent}
					icon={<CancelIcon width={30} height={30} className="mr-2" />}
				/>
				<ModalButton
					title="OK"
					classes="bg-green-CPH"
					callBackFunction={handleCheckInEvent}
					icon={<CheckIcon width={25} height={25} className="mr-2" />}
				/>
			</FlexColumn>
		</article>
	);
};
