import { HandlerRefPair } from '../types';

export const handlerRefPairs: HandlerRefPair[] = [
	{ handlerName: 'Aviator', abbreviation: 'AVIATOR' },
	{ handlerName: 'CFS', abbreviation: 'CFS' },
	{ handlerName: 'Menzies', abbreviation: 'MENZIES' },
	{ handlerName: 'SAS GH', abbreviation: 'SGS' },
];

export const handlerRefPairForAllHandlers: HandlerRefPair = {
	handlerName: 'All',
	abbreviation: 'ALL',
};
