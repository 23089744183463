import timestampStyle from "../../styles/table/TableRow.module.css";

export const TableDatestamp = ({
  date,
  classes,
}: {
  date: Date;
  classes?: string;
}) => {
  const classList = `${timestampStyle.button} ${classes ? classes : ""}`;
  const timestamp = `${date.getDay()} ${date.toLocaleDateString(undefined, {
    month: "short",
  })} ${date.getHours()}:${date.getMinutes()}`;

  return <p className={classList}>{timestamp}</p>;
};
