import { createSlice } from '@reduxjs/toolkit';
import { BaggageArrivalFlightState } from '../../types';
import { HandlerRefPair } from '../../types';
import { getBaggageArrivalFlightState, updateBaggageArrivalFlightState } from './action';

export interface BaggageArrivalStateSlice {
	error: boolean;
	baggageArrivalFlightState: BaggageArrivalFlightState | undefined;
	lastModified?: string;
	selectedHandler?: HandlerRefPair;
}

const initialState: BaggageArrivalStateSlice = {
	error: false,
	baggageArrivalFlightState: undefined,
};

const baggageArrivalFlightStateSlice = createSlice({
	name: 'baggage-arrival-state',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBaggageArrivalFlightState.rejected, (state) => {
			state.error = true;
			state.baggageArrivalFlightState = undefined;
		});
		builder.addCase(getBaggageArrivalFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null) {
				state.baggageArrivalFlightState = action.payload;
			} else {
				state.baggageArrivalFlightState = undefined;
			}
		});
		builder.addCase(updateBaggageArrivalFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null && action.payload !== undefined) {
				state.baggageArrivalFlightState = action.payload;
			}
		});
		builder.addCase(updateBaggageArrivalFlightState.rejected, (state) => {
			state.error = true;
			state.baggageArrivalFlightState = undefined;
		});
	},
});

export default baggageArrivalFlightStateSlice.reducer;
