import { ReactComponent as ArrivalIcon } from '../../assets/icons/xeu-arrival-icon.svg';
import { ReactComponent as TransferIcon } from '../../assets/icons/xeu-transfer-icon.svg';
import { getBeltLabel } from '../../service/beltDisplayNameService';
import styles from '../../styles/Header.module.css';
import {
	ArrivalBeltName,
	BaggageLabelType,
	HeaderLabelContent,
	TransferBeltName,
} from '../../types';

export const HeaderLabel = ({ headerLabelContent }: { headerLabelContent: HeaderLabelContent }) => {
	return (
		<div
			className={styles.headerLabel}
			style={{
				backgroundColor: headerLabelContent.bgColor
					? `var(${headerLabelContent.bgColor})`
					: 'none',
				color: `var(${headerLabelContent.fontColor})`,
				justifyContent: headerLabelContent.bgColor ? 'space-between' : 'center',
			}}
		>
			{headerLabelContent.icon} <p>{headerLabelContent.title}</p>
			{headerLabelContent.dotType && <div className={headerLabelContent.dotType}></div>}
			{headerLabelContent.beltLabel && <p>{headerLabelContent.beltLabel}</p>}
			{headerLabelContent.gateLabel && <p>{headerLabelContent.gateLabel}</p>}
		</div>
	);
};
