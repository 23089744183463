import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Components
import { Table } from '../components/table/index';
import { AEUTEUReallocationListView, AEUTEUTableRow } from '../components/table/AEUTEU/index';
import { ModalOverlay } from '../components/modal/index';
import { ReactComponent as ScrollUpIcon } from '../assets/icons/xeu-scroll-up.svg';
import { ReactComponent as ScrollDownIcon } from '../assets/icons/xeu-scroll-down.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/xeu-error.svg';

// Store
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { getBaggageArrivalFlightState } from '../store/baggageArrivalFlightState/action';
import { getBaggageReallocationState } from '../store/BaggageReallocationState/action';
import { setCurrentBelt, setSelectedBelt } from '../store/belt';
// Services
import { filterFlightsOnArrivalStatus, filterFlightsOnBelt } from '../service/filterService';
import { simpleSortingByName, sortAEUAndTEUFlights } from '../service/sortingService';
import { getBeltLabel } from '../service/beltDisplayNameService';
// Styles
import tableRowStyles from '../styles/table/TableRow.module.css';
import buttonStyles from './../styles/UI/Button.module.css';
import navigationStyles from '../styles/homepage/Navigation.module.css';
import errorStyles from '../styles/error/error.module.css';
// Types
import {
	BaggageArrivalFlight,
	ArrivalFlightStatusObject,
	ArrivalBeltName,
	ArrivalBelt,
} from '../types';
import { setPath } from '../store/path';
import useEventTracker from '../hooks/useEventTracker';
import { timeBeforeReturnEvent } from '../utils/timers';
import UrlParam from '../utils/urlParams';
import { OutOfOrderBlock } from '../components/UI';
import { getArrivalBeltStatus } from '../store/belt/action';
import beltStatusService from '../service/beltStatusService';

export const AEUHome = () => {
	const dispatch = useAppDispatch();
	const beltStatus = useAppSelector((state) => state.beltStore.arrivalBeltStatus);
	const errorOnBeltStatusCall = useAppSelector((state) => state.beltStore.arrivalBeltStatusError);
	const selectedBelt = useAppSelector((state) => state.beltStore.selectedBelt);
	const error = useAppSelector((state) => state.baggageArrivalFlightState.error);
	const baggageArrivalFlightState = useAppSelector(
		(state) => state.baggageArrivalFlightState.baggageArrivalFlightState
	);
	const interceptorIsReady = useAppSelector(
		(state) => state.apiConfigurationstate.interceptorIsReady
	);
	const arrivalReallocationState = useAppSelector(
		(state) => state.baggageReallocationStateStore.baggageReaalocationState?.data.arrivalBelts
	);

	const currentBelt = useAppSelector((state) => state.beltStore.currentBelt);

	const [flightStatuses, setFlightStatuses] = useState<ArrivalFlightStatusObject>();
	const [sortedData, setSortedData] = useState<ArrivalFlightStatusObject>();
	const [completedListOpen, setCompletedListOpen] = useState<boolean>(false);
	const [allowedBelts, setAllowedBelts] = useState<string[]>();
	const [beltStatusLabels, setBeltStatusLabels] = useState<string[]>();

	const beltName = UrlParam.beltParamInUrl?.toUpperCase() as ArrivalBeltName;
	useEffect(() => {
		// Also set selectedBelt to the retrieved belt -
		// and ask for data from BE.
		const pathName = document.location.pathname;
		if (beltName) {
			dispatch(setSelectedBelt(beltName));
			dispatch(setCurrentBelt(beltName));
		}
		dispatch(setPath(pathName));
		// Check if keycloak interceptor is ready
		if (interceptorIsReady) {
			dispatch(getArrivalBeltStatus());
			dispatch(getBaggageArrivalFlightState());
			dispatch(getBaggageReallocationState());
		}
	}, [interceptorIsReady]);

	useEffect(() => {
		if (baggageArrivalFlightState) {
			if (baggageArrivalFlightState !== undefined && currentBelt) {
				// Only show data relevant to the current belt
				const filteredOnBelt = filterFlightsOnBelt(
					currentBelt,
					baggageArrivalFlightState.data
				);
				setBeltStatusLabels(
					beltStatusService.filterAndExtractBaltStateMessagesBasedOnCurrentBelt(
						currentBelt,
						beltStatus!
					)
				);

				// Sort data according to completed and "incoming" flights
				const flightStatusObject = filterFlightsOnArrivalStatus(
					filteredOnBelt as BaggageArrivalFlight[]
				);
				setFlightStatuses(flightStatusObject);
			}
		}
	}, [baggageArrivalFlightState]);

	useEffect(() => {
		// Once flights have been filtered into completed and open flights, sort them correctly.
		if (flightStatuses) updateSortedFlights(flightStatuses);
	}, [flightStatuses]);

	const updateSortedFlights = (flightStatusObject: ArrivalFlightStatusObject) => {
		// Flights are sorted in two ways: Open flights are arranged in ascending order,
		//  while completed flights are organized in descending order based on their 'inBlock' times.
		const sortedFlights = sortAEUAndTEUFlights<ArrivalFlightStatusObject>(flightStatusObject);
		setSortedData(sortedFlights);
	};

	const toggleCompletedFlightList = () => {
		setCompletedListOpen((prevState) => !prevState);
	};

	useEffect(() => {
		// This useEffect handles toggling scroll on both body and completed list, when
		// overlays are opened.
		const scrollWrapper = document.getElementById('completed-list-scroll-wrapper');
		const body = document.querySelector('body');
		const completedList = document.getElementById('completed-list');

		if (completedListOpen) {
			scrollWrapper?.classList.add('overflow-y-scroll');
			scrollWrapper?.classList.remove('overflow-hidden');
			completedList?.classList.remove('completed-list__closed');
			body?.classList.add('overflow-hidden');
			body?.classList.remove('overflow-y-scroll');
		} else {
			scrollWrapper?.classList.remove('overflow-y-scroll');
			scrollWrapper?.classList.add('overflow-hidden');
			completedList?.classList.add('completed-list__closed');
			body?.classList.remove('overflow-hidden');
			body?.classList.add('overflow-y-scroll');
		}
	}, [completedListOpen]);

	const createListOfAllowedBelts = (reallocationState: ArrivalBelt[]) => {
		// First retrieve the arrivalObject for the currentBelt
		// Then retrieve list of belts that the current Belt is allowed to access for baggage reallocation.
		// If list does not include the current Belt, then add it to allow user to click on currentBelt in the navigation bat
		// Once the list is complete, sort it and set the allowedBelts value.
		const arrivalObject = reallocationState.find((item) => {
			return item.arrivalBeltRef === currentBelt;
		});

		let listOfAllowedBelts: ArrivalBeltName[] = [];

		listOfAllowedBelts = listOfAllowedBelts.concat(arrivalObject!.allowMoveFromList);

		if (!listOfAllowedBelts?.includes(currentBelt as ArrivalBeltName)) {
			listOfAllowedBelts?.push(currentBelt as ArrivalBeltName);
		}

		let sortedListOfAllowedBelts: ArrivalBeltName[];

		listOfAllowedBelts
			? (sortedListOfAllowedBelts = simpleSortingByName(
					listOfAllowedBelts
			  ) as ArrivalBeltName[])
			: (sortedListOfAllowedBelts = []);

		setAllowedBelts(sortedListOfAllowedBelts);
	};

	useEffect(() => {
		// When both the currentBelt and arrivalReallocationState is ready, create list of allowed Belt to reallocate from.
		if (arrivalReallocationState && currentBelt)
			createListOfAllowedBelts(arrivalReallocationState);
	}, [arrivalReallocationState, currentBelt]);

	// Keeps track of the belt selected by the user (not URL)
	const handleTransferBeltClick = (transferBelt: ArrivalBeltName) => {
		dispatch(setSelectedBelt(transferBelt));
	};

	const handleResumeClick = () => {
		// Return to main screen and All filtering.
		toggleCompletedFlightList();
	};

	// Handle redirect bacl to "All" filter, if screen is not touched for x seconds
	const secondsSinceLastClick = useEventTracker('click');
	const secondsSinceLastTouch = useEventTracker('touch');

	useEffect(() => {
		if (secondsSinceLastClick && secondsSinceLastClick >= timeBeforeReturnEvent) {
			dispatch(setSelectedBelt(beltName));
		}
	}, [secondsSinceLastClick]);

	useEffect(() => {
		if (secondsSinceLastTouch && secondsSinceLastTouch >= timeBeforeReturnEvent) {
			dispatch(setSelectedBelt(beltName));
		}
	}, [secondsSinceLastTouch]);

	return (
		<>
			{error ? (
				<div className="h-full w-full-important flex items-center justify-center">
					<OutOfOrderBlock />
				</div>
			) : (
				<>
					<section className={`flex flex-col items-center justify-center mb-[10rem]`}>
						{/* Insert check beltInfo.allowFrom - show navigation-tab-bar if true */}
						{allowedBelts && allowedBelts.length > 1 && (
							<>
								<article
									key="AEU-navigation-bar"
									className={navigationStyles.tabNavigationBar}
								>
									{allowedBelts.map((x) => (
										<button
											key={x}
											className={`${buttonStyles.beltNavigationButton} ${
												buttonStyles.smallButtonWidth
											} ${
												selectedBelt! === x
													? buttonStyles.blueButtonNoBorder
													: buttonStyles.beltNavigationGhostButton
											}`}
											onClick={() =>
												handleTransferBeltClick(x as ArrivalBeltName)
											}
										>
											{getBeltLabel(x as ArrivalBeltName)}
										</button>
									))}
								</article>
							</>
						)}
						{beltStatusLabels && beltStatusLabels.length > 0 && (
							<ul className={errorStyles.beltErrorWrapper}>
								{beltStatusLabels.map((error) => (
									<li className={errorStyles.beltErrorItem}>
										<ErrorIcon /> {error}
									</li>
								))}
							</ul>
						)}
						{errorOnBeltStatusCall && (
							<>
								<p className={errorStyles.beltErrorWrapper}>
									<span className={errorStyles.beltErrorItem}>
										<ErrorIcon /> {errorOnBeltStatusCall}
									</span>
								</p>
							</>
						)}
						<article
							key="AEU-main-list"
							className={`list-box-wrapper ${tableRowStyles.tableBorder}`}
						>
							{sortedData && currentBelt === selectedBelt! ? (
								<Table>
									<tbody>
										{sortedData.openFlightsCheckedIn &&
											sortedData.openFlightsCheckedIn.map((flight) => (
												<AEUTEUTableRow
													flight={flight as BaggageArrivalFlight}
													key={flight.flightRef}
												/>
											))}
										{sortedData.openFlightsNotCheckedIn &&
											sortedData.openFlightsNotCheckedIn.map((flight) => (
												<AEUTEUTableRow
													flight={flight as BaggageArrivalFlight}
													key={flight.flightRef}
												/>
											))}
									</tbody>
								</Table>
							) : (
								<>
									<AEUTEUReallocationListView
										baggageFlightState={baggageArrivalFlightState!}
									/>
								</>
							)}
						</article>
						{currentBelt === selectedBelt && (
							// List of completed flights
							<article
								key="AEU-completed-list"
								id="completed-list"
								className={`completed-list completed-list__closed ${
									completedListOpen ? null : 'completed-list__box-shadow'
								}`}
							>
								<div
									className="completed-list__headline"
									onClick={toggleCompletedFlightList}
								>
									{completedListOpen ? (
										<ScrollDownIcon width={30} />
									) : (
										<ScrollUpIcon width={30} />
									)}
									<p className="small-title mt-2">Completed flights</p>
								</div>
								{sortedData && sortedData.completedFlights.length > 0 && (
									<div
										className="flex flex-col items-center justify-start mt-3 w-[95%]"
										id="completed-list-scroll-wrapper"
									>
										{sortedData &&
											sortedData &&
											sortedData.completedFlights.length > 0 && (
												<Table classes="w-full">
													<tbody>
														{sortedData.completedFlights.map(
															(flight) => (
																<AEUTEUTableRow
																	flight={
																		flight as BaggageArrivalFlight
																	}
																	key={`completed_${flight.flightRef}`}
																	isCompletedList
																	hideGhostData={flight.isGhost}
																	handleResumeClick={() => {
																		handleResumeClick();
																	}}
																/>
															)
														)}
													</tbody>
												</Table>
											)}
									</div>
								)}
							</article>
						)}
						{completedListOpen && (
							<ModalOverlay onClick={toggleCompletedFlightList}></ModalOverlay>
						)}
					</section>
				</>
			)}
		</>
	);
};
