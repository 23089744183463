import React from 'react';
import { Timestamp } from '../../UI';
import { ReactComponent as LastBagIcon } from '../../../assets/icons/xeu-blue-check-stroke.svg';
// Styles
import tableRowStyles from '../../../styles/table/TableRow.module.css';
import { BaggageArrivalFlight, BaggageTransferFlight } from '../../../types';

const ReallocatedLastBagTableBlock = ({ flight }: { flight: BaggageArrivalFlight }) => {
	return (
		<div className={`h-100  ${tableRowStyles.tableRowBlock} w-[131px] justify-end`}>
			<LastBagIcon width={25} height={25} />
			<Timestamp
				classes={`time-tracker-timestamp ml-1 ${tableRowStyles.aeuTeuTableRowTimestamp}`}
				date={flight.allocationLastBag}
			/>
		</div>
	);
};

export default ReallocatedLastBagTableBlock;
