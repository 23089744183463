import { useState } from 'react';
import { BaggageTransferFlight, ErrorBlurb } from '../../types';
// Components
import { ModalButton, FlexColumn, ErrorBox } from '../UI/index';
import { ReactComponent as CheckIcon } from './../../assets/icons/xeu-white-check.svg';
import { ReactComponent as CancelIcon } from './../../assets/icons/xeu-white-cancel.svg';
import { ReactComponent as BlueInfoIcon } from './../../assets/icons/xeu-blue-information.svg';
// Services
import api, { API_PATHS } from '../../service/api';
import { getBeltLabel } from '../../service/beltDisplayNameService';
// Store
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { closeModal } from '../../store/modalStore';
// Styles
import modalStyles from '../../styles/modal/Modal.module.css';
import { setSelectedBelt } from '../../store/belt';
import { ModalHeaderBlock } from './ModalHeaderBlock';
import { setFlightToAnimate } from '../../store/animation';

export const TransferModal = ({ flight }: { flight: BaggageTransferFlight }) => {
	const storeDispatch = useAppDispatch();
	const handleCloseModalEvent = () => {
		storeDispatch(closeModal());
	};
	const currentBelt = useAppSelector((state) => state.beltStore.currentBelt);

	// Handle move to current belt - this also happens in AEUTEUReallocationRow.tsx
	// TO DO: refactor to just one function shared between components
	const handleMoveToCurrentTransferBelt = async () => {
		setErrorBlurb(null);
		try {
			const response = await api.post(API_PATHS.reallocate, state);
			if (response.status === 208) {
			} else if (response.status < 300) {
				storeDispatch(setFlightToAnimate(flight.flightRef));
				storeDispatch(setSelectedBelt(currentBelt));
			}
			storeDispatch(closeModal());
		} catch (error) {
			setErrorBlurb({
				errorMessage: 'Something went wrong.',
				actionMessage: 'Close modal and try again.',
			});
		}
	};

	const [errorBlurb, setErrorBlurb] = useState<ErrorBlurb | null>();

	const state = {
		flightRef: flight.flightRef,
		baggageType: 'TRANSFER',
		fromBelt: flight.beltName,
		toBelt: currentBelt,
	};

	return (
		<article
			className={modalStyles.modal}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<ModalHeaderBlock flight={flight} />

			{errorBlurb ? (
				errorBlurb && <ErrorBox error={errorBlurb} />
			) : (
				<div className="mb-7 blue-wrapper flex flex-col gap-3 justify-center items-center rounded-md">
					<BlueInfoIcon width={30} />
					<p>Screening is not required</p>
					<p className="text-black">
						Do you want to move it to Transfer {getBeltLabel(currentBelt!)}
					</p>
				</div>
			)}

			<FlexColumn>
				<ModalButton
					title="Cancel"
					classes="bg-red-CPH mr-6"
					callBackFunction={handleCloseModalEvent}
					icon={<CancelIcon width={27} height={27} className="mr-2" />}
				/>
				<ModalButton
					title="OK"
					classes="bg-green-CPH"
					callBackFunction={handleMoveToCurrentTransferBelt}
					icon={<CheckIcon width={22} height={22} className="mr-2" />}
				/>
			</FlexColumn>
		</article>
	);
};
