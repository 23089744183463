export const TableTitle = ({
	title,
	classes,
	bold,
}: {
	title: string;
	classes?: string;
	bold?: boolean;
}) => {
	const classList = `table-title ${classes ? classes : ''} ${bold ? 'font-bold' : ''}`;

	return <h4 className={classList}>{title}</h4>;
};
