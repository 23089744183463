import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// components
import { Table } from '../components/table/index';
import { TransferModal, ModalOverlay } from '../components/modal/index';
import { OutOfOrderBlock } from '../components/UI/index';
import { AEUTEUReallocationListView, AEUTEUTableRow } from '../components/table/AEUTEU/index';
import { ReactComponent as ScrollUpIcon } from '../assets/icons/xeu-scroll-up.svg';
import { ReactComponent as ScrollDownIcon } from '../assets/icons/xeu-scroll-down.svg';
// Store
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { getBaggageTransferFlightState } from '../store/baggageTransferFlightState/action';
import { getBaggageReallocationState } from '../store/BaggageReallocationState/action';
import { setCurrentBelt, setScreeeningStateForBelts, setSelectedBelt } from '../store/belt';
// Services
import { filterFlightsOnTransferStatus, filterFlightsOnBelt } from '../service/filterService';
import { simpleSortingByName, sortAEUAndTEUFlights } from '../service/sortingService';
import { getBeltLabel } from '../service/beltDisplayNameService';
// Styles
import tableRowStyles from '../styles/table/TableRow.module.css';
import styles from './../styles/UI/Button.module.css';
// Types
import {
	BaggageTransferFlight,
	TransferFlightStatusObject,
	TransferBeltName,
	TransferBelt,
} from '../types';
import { setPath } from '../store/path';
import useEventTracker from '../hooks/useEventTracker';
import { timeBeforeReturnEvent } from '../utils/timers';
import UrlParam from '../utils/urlParams';

export const TEUHome = () => {
	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();

	const openModal = useAppSelector((state) => state.modalStore.open);
	const selectedBelt = useAppSelector((state) => state.beltStore.selectedBelt);
	const modalFlight = useAppSelector((state) => state.modalStore.flight);
	const error = useAppSelector((state) => state.baggageTransferFlightState.error);
	const baggageTransferFlightState = useAppSelector(
		(state) => state.baggageTransferFlightState.baggageTransferFlightState
	);
	const currentBelt = useAppSelector((state) => state.beltStore.currentBelt);
	const interceptorIsReady = useAppSelector(
		(state) => state.apiConfigurationstate.interceptorIsReady
	);
	const [flightStatuses, setFlightStatuses] = useState<TransferFlightStatusObject>();
	const [sortedData, setSortedData] = useState<TransferFlightStatusObject>();
	const [completedListOpen, setCompletedListOpen] = useState<boolean>(false);
	const [allowedBelts, setAllowedBelts] = useState<string[]>();

	const transferReallocationState = useAppSelector(
		(state) => state.baggageReallocationStateStore.baggageReaalocationState?.data.transferBelts
	);
	const listOfBeltsWithScreening = useAppSelector(
		(state) =>
			state.baggageReallocationStateStore.baggageReaalocationState?.data
				.beltsWithScreeningActive
	);

	const beltName = UrlParam.beltParamInUrl?.toUpperCase() as TransferBeltName;

	useEffect(() => {
		// When application loads, retrieve beltName from URL and dispatch it to the store.
		// Also set selectedBelt to the retrieved belt -
		// and ask for data from BE.
		const pathName = document.location.pathname;
		if (beltName) {
			dispatch(setCurrentBelt(beltName));
			dispatch(setSelectedBelt(beltName));
		}
		dispatch(setPath(pathName));
		// Check if keycloak interceptor is ready
		if (interceptorIsReady) {
			dispatch(getBaggageTransferFlightState());
			dispatch(getBaggageReallocationState());
		}
	}, [interceptorIsReady]);

	useEffect(() => {
		if (baggageTransferFlightState) {
			if (baggageTransferFlightState !== undefined && currentBelt) {
				// Only show data relevant to the current belt
				const filteredOnBelt = filterFlightsOnBelt(
					currentBelt,
					baggageTransferFlightState.data
				);

				// Sort data according to completed and "incoming" flights
				const flightStatusObject = filterFlightsOnTransferStatus(filteredOnBelt);
				setFlightStatuses(flightStatusObject);
			}
		}
	}, [baggageTransferFlightState]);

	useEffect(() => {
		// Once flights have been filtered into completed and open flights, sort them correctly.
		if (flightStatuses) updateSortedFlights(flightStatuses);
	}, [flightStatuses]);

	const updateSortedFlights = (flightStatusObject: TransferFlightStatusObject) => {
		// Flights are sorted in two ways: Open flights are arranged in ascending order,
		//  while completed flights are organized in descending order based on their 'inBlock' times.
		const sortedFlights = sortAEUAndTEUFlights<TransferFlightStatusObject>(flightStatusObject);
		setSortedData(sortedFlights);
	};

	const toggleCompletedFlightList = () => {
		setCompletedListOpen((prevState) => !prevState);
	};

	useEffect(() => {
		// This useEffect handles toggling scroll on both body and completed list, when
		// overlays are opened.
		const scrollWrapper = document.getElementById('completed-list-scroll-wrapper');
		const body = document.querySelector('body');
		const completedList = document.getElementById('completed-list');

		if (completedListOpen) {
			scrollWrapper?.classList.add('overflow-y-scroll');
			scrollWrapper?.classList.remove('overflow-hidden');
			completedList?.classList.remove('completed-list__closed');
			body?.classList.add('overflow-hidden');
			body?.classList.remove('overflow-y-scroll');
		} else {
			scrollWrapper?.classList.remove('overflow-y-scroll');
			scrollWrapper?.classList.add('overflow-hidden');
			completedList?.classList.add('completed-list__closed');
			body?.classList.remove('overflow-hidden');
			body?.classList.add('overflow-y-scroll');
		}
	}, [completedListOpen]);

	// Keepts track of the belt selected by the user (not URL)
	const handleTransferBeltClick = (transferBelt: TransferBeltName) => {
		dispatch(setSelectedBelt(transferBelt));
	};

	useEffect(() => {
		// When both the currentBelt and arrivalReallocationState is ready, create list of allowed Belt to reallocate from
		// and dispatch the screeningState needed to check for screening during reallocation.
		if (transferReallocationState && currentBelt) {
			createListOfAllowedBelts(transferReallocationState);
			dispatch(setScreeeningStateForBelts(listOfBeltsWithScreening));
		}
	}, [transferReallocationState, currentBelt]);

	const createListOfAllowedBelts = (reallocationState: TransferBelt[]) => {
		// First retrieve the arrivalObject for the currentBelt
		// Then retrieve list of belts that the current Belt is allowed to access for baggage reallocation.
		// If list does not include the current Belt, then add it to allow user to click on currentBelt in the navigation bat
		// Once the list is complete, sort it and set the allowedBelts value.
		const transferObject = reallocationState.find((item) => {
			return item.transferBeltRef === currentBelt;
		});

		let listOfAllowedBelts: TransferBeltName[] = [];

		listOfAllowedBelts = listOfAllowedBelts.concat(transferObject!.allowMoveFromList);

		if (!listOfAllowedBelts?.includes(currentBelt as TransferBeltName)) {
			listOfAllowedBelts?.push(currentBelt as TransferBeltName);
		}

		let sortedListOfAllowedBelts: TransferBeltName[];

		listOfAllowedBelts
			? (sortedListOfAllowedBelts = simpleSortingByName(
					listOfAllowedBelts
			  ) as TransferBeltName[])
			: (sortedListOfAllowedBelts = []);

		setAllowedBelts(sortedListOfAllowedBelts);
	};

	const handleResumeClick = (flight: BaggageTransferFlight) => {
		// Return to main screen and All filtering.
		// Enlarge clicked Flight
		toggleCompletedFlightList();
	};

	// Handle redirect bacl to "All" filter, if screen is not touched for x seconds
	const secondsSinceLastClick = useEventTracker('click');
	const secondsSinceLastTouch = useEventTracker('touch');

	useEffect(() => {
		if (secondsSinceLastClick && secondsSinceLastClick >= timeBeforeReturnEvent) {
			dispatch(setSelectedBelt(beltName));
		}
	}, [secondsSinceLastClick]);

	useEffect(() => {
		if (secondsSinceLastTouch && secondsSinceLastTouch >= timeBeforeReturnEvent) {
			dispatch(setSelectedBelt(beltName));
		}
	}, [secondsSinceLastTouch]);

	return (
		<>
			{error ? (
				<div className="h-full w-full-important flex items-center justify-center">
					<OutOfOrderBlock />
				</div>
			) : (
				<>
					<section className={`flex-col flex items-center justify-center mb-[10rem]`}>
						{allowedBelts && allowedBelts.length > 1 && (
							<>
								<article
									className="bg-white flex gap-5 w-fit mt-8 rounded p-[4px]"
									key="navigation-bar"
								>
									{allowedBelts.map((x) => (
										<button
											className={`${styles.beltNavigationButton} ${
												styles.smallButtonWidth
											} ${
												selectedBelt! === x
													? styles.blueButtonNoBorder
													: styles.beltNavigationGhostButton
											}`}
											onClick={() =>
												handleTransferBeltClick(x as TransferBeltName)
											}
										>
											{getBeltLabel(x as TransferBeltName)}
										</button>
									))}
								</article>
							</>
						)}
						<article
							className={`list-box-wrapper ${tableRowStyles.tableBorder}`}
							key="main-list"
						>
							{sortedData && currentBelt === selectedBelt ? (
								<Table>
									<tbody>
										{sortedData.openFlightsCheckedIn &&
											sortedData.openFlightsCheckedIn.map((flight) => (
												<AEUTEUTableRow
													flight={flight as BaggageTransferFlight}
													key={flight.flightRef}
												/>
											))}
										{sortedData.openFlightsNotCheckedIn &&
											sortedData.openFlightsNotCheckedIn.map((flight) => (
												<AEUTEUTableRow
													flight={flight as BaggageTransferFlight}
													key={flight.flightRef}
												/>
											))}
									</tbody>
								</Table>
							) : (
								<AEUTEUReallocationListView
									baggageFlightState={baggageTransferFlightState!}
								/>
							)}
						</article>
						{currentBelt === selectedBelt && (
							<article
								key="completed-list"
								id="completed-list"
								className={`completed-list completed-list__closed ${
									completedListOpen ? null : 'completed-list__box-shadow'
								}`}
							>
								<div
									className="completed-list__headline"
									onClick={toggleCompletedFlightList}
								>
									{completedListOpen ? (
										<ScrollDownIcon width={30} />
									) : (
										<ScrollUpIcon width={30} />
									)}
									<p className="small-title mt-2">Completed flights</p>
								</div>
								{sortedData && sortedData.completedFlights.length > 0 && (
									<div
										className="flex flex-col items-center justify-start mt-3"
										id="completed-list-scroll-wrapper"
									>
										{sortedData && (
											<Table classes="w-full">
												<tbody>
													{sortedData.completedFlights.map((flight) => (
														<AEUTEUTableRow
															flight={flight as BaggageTransferFlight}
															key={`completed_${flight.flightRef}`}
															isCompletedList
															handleResumeClick={(flight) => {
																handleResumeClick(
																	flight as BaggageTransferFlight
																);
															}}
														/>
													))}
												</tbody>
											</Table>
										)}
									</div>
								)}
							</article>
						)}

						{completedListOpen && (
							<ModalOverlay onClick={toggleCompletedFlightList}></ModalOverlay>
						)}
					</section>
					{openModal && modalFlight && (
						<ModalOverlay>
							<TransferModal flight={modalFlight as BaggageTransferFlight} />
						</ModalOverlay>
					)}
				</>
			)}
		</>
	);
};
