import { createSlice } from '@reduxjs/toolkit';
import { BaggageEntryFlight, BaggageTransferFlight } from '../types';

type InitialState = {
	open: boolean;
	flight?: BaggageEntryFlight | BaggageTransferFlight;
};

const initialState: InitialState = {
	open: false,
};

const modalSlice = createSlice({
	name: 'modal',
	initialState: initialState,
	reducers: {
		openModal: (state, action) => {
			state.open = true;
			state.flight = action.payload;
		},
		closeModal: (state) => {
			state.open = false;
			state.flight = undefined;
		},
	},
});

export default modalSlice.reducer;

export const { openModal, closeModal } = modalSlice.actions;
