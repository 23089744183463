// Components
import { ReactComponent as TransferIcon } from '../../assets/icons/xeu-transfer-icon.svg';
import { ReactComponent as Logo } from './../../assets/icons/bagstage-logo.svg';
import { ReactComponent as ArrivalIcon } from './../../assets/icons/xeu-arrival-icon.svg';
import { Clock } from './Clock';
import { HeaderLabel } from './HeaderLabel';
// Types
import { ArrivalBeltName, HeaderLabelContent } from '../../types';
// Styles
import headerStyles from '../../styles/Header.module.css';
import { getBeltLabel } from '../../service/beltDisplayNameService';
import UrlParam from '../../utils/urlParams';
import Labels from '../../utils/labels';

export const Header = () => {
	const belt = UrlParam.beltParamInUrl?.toUpperCase() as ArrivalBeltName;
	const gate = UrlParam.gateParamInUrl!;
	const isStaging = UrlParam.isStaging;

	const getHeaderLabelContent = (): HeaderLabelContent | undefined => {
		if (document.location.pathname === '/aeu') {
			return {
				title: 'Arrival',
				bgColor: '--purple',
				fontColor: '--white',
				beltLabel: getBeltLabel(belt!),
				icon: <ArrivalIcon width={40} height={40} />,
				dotType: 'white-dot',
			};
		} else if (document.location.pathname.toLocaleLowerCase() === '/teu') {
			return {
				title: 'Transfer',
				bgColor: '--yellow-CPH',
				fontColor: '--dark-blue-CPH',
				icon: <TransferIcon width={40} height={40} />,
				beltLabel: getBeltLabel(belt!),
				dotType: 'blue-dot',
			};
		} else if (document.location.pathname.toLocaleLowerCase() === '/oeu') {
			return {
				title: 'Oddsize',
				fontColor: '--white',
				icon: <ArrivalIcon width={40} height={40} />,
				beltLabel: getBeltLabel(belt!),
			};
		} else if (document.location.pathname.toLocaleLowerCase() === '/eeu') {
			return {
				title: 'Entry',
				fontColor: '--white',
				icon: <ArrivalIcon width={40} height={40} />,
				gateLabel: gate!,
			};
		}
	};

	return (
		<div className={headerStyles.header}>
			<Logo height={50} width={220} />
			{isStaging && <div className="red-striped-container">{Labels.testSystem}</div>}
			<HeaderLabel headerLabelContent={getHeaderLabelContent()!} />
			<div className="flex gap-10">
				<Clock />
			</div>
		</div>
	);
};
