import React from 'react';
import { ReactComponent as MovedToIcon } from '../../../assets/icons/xeu-blue-arrow-right.svg';
import tableRowStyles from '../../../styles/table/TableRow.module.css';

const ReallocatedTableBlock = () => {
	return (
		<div className={`h-100  ${tableRowStyles.tableRowBlock} w-[160px] gap-3`}>
			<MovedToIcon width={25} height={25} />
			<p className="ml-1">Moved</p>
		</div>
	);
};

export default ReallocatedTableBlock;
