import { useState, useEffect } from 'react';
// Components
import { Table } from '../table/index';
import EEUTableRow from '../table/EEU/EEUTableRow';
import { ReactComponent as ScrollUpIcon } from '../../assets/icons/xeu-scroll-up.svg';
import { ReactComponent as ScrollDownIcon } from '../../assets/icons/xeu-scroll-down.svg';
// Hooks
import { handleFilteringOnSelectedHandlerAndSortingData } from '../../hooks/useFilterOnHandler';
// Types
import { BaggageEntryFlight, FlightStatusObject, HandlerRefPair } from '../../types';
// Styles
import listStyle from '../../styles/UI/SlideUpList.module.css';
import { TabBarNavigation } from '../table/EEUOEU/TabBarNavigation';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedHandler } from '../../store/baggageEntryFlightState';

export const SlideUpList = ({
	flightStatuses,
}: {
	flightStatuses: FlightStatusObject | undefined;
}) => {
	const dispatch = useAppDispatch();
	const [activeSlideUp, setActiveSlideUp] = useState<boolean>();
	const [sortedData, setSortedData] = useState<FlightStatusObject>();

	const toggleSlideUpList = () => {
		setActiveSlideUp((prevState) => !prevState);
	};

	// Handle scroll functionality, based on whether the list is open or not.
	useEffect(() => {
		const body = document.querySelector('body');
		const scrollWrapper = document.getElementById('completed-list-scroll-wrapper');
		if (body && scrollWrapper && activeSlideUp) {
			scrollWrapper.classList.add('overflow-y-scroll');
			body.classList.remove('overflow-y-scroll');
			body.classList.add('overflow-y-hidden');
		} else if (body && scrollWrapper && !activeSlideUp) {
			body.classList.add('overflow-y-scroll');
			body.classList.remove('overflow-y-hidden');
			scrollWrapper.scrollTop = 0;
			scrollWrapper.classList.remove('overflow-y-scroll');
		}
	}, [activeSlideUp]);

	const selectedHandler = useAppSelector(
		(state) => state.baggageEntryFlightState.selectedHandler
	);

	useEffect(() => {
		filterOnHandler(selectedHandler);
	}, [flightStatuses]);

	const filterOnHandler = (handlerRefPair: HandlerRefPair) => {
		dispatch(setSelectedHandler(handlerRefPair));
		const sortedAndFilteredFeed = handleFilteringOnSelectedHandlerAndSortingData(
			handlerRefPair,
			flightStatuses
		);
		setSortedData(sortedAndFilteredFeed);
	};

	const filterFlights = (handlerRefPair: HandlerRefPair) => {
		filterOnHandler(handlerRefPair);
	};

	return (
		<section
			className={`${listStyle.slideUpList} ${
				activeSlideUp ? listStyle.slideUpListActive : null
			}`}
		>
			<div className="completed-list__headline" onClick={toggleSlideUpList}>
				{activeSlideUp ? <ScrollDownIcon width={30} /> : <ScrollUpIcon width={30} />}
				<p className="small-title mt-2">Completed flights</p>
			</div>
			<TabBarNavigation
				onClick={(handlerRefPair) => filterFlights(handlerRefPair)}
				classes="w-full"
			/>

			{sortedData?.completedFlights && sortedData.completedFlights.length > 0 && (
				<div className=" flex flex-col items-center" id="completed-list-scroll-wrapper">
					<Table classes={`rounded-2xl`}>
						<tbody>
							{sortedData?.completedFlights.map((flight) => (
								<EEUTableRow
									flight={flight as BaggageEntryFlight}
									key={flight.flightRef}
								/>
							))}
						</tbody>
					</Table>
				</div>
			)}
		</section>
	);
};
