import { createSlice } from '@reduxjs/toolkit';
export type BaggageType = 'PRIMARY' | 'TRANSFER';

type InitialState = {
	path: string;
	baggageType?: BaggageType;
};

const initialState: InitialState = {
	path: '',
};

const pathSlice = createSlice({
	name: 'path',
	initialState: initialState,
	reducers: {
		setPath: (state, action) => {
			state.path = action.payload;
			state.baggageType = state.path === '/aeu' ? 'PRIMARY' : 'TRANSFER';
		},
	},
});

export default pathSlice.reducer;

export const { setPath } = pathSlice.actions;
