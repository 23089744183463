import React from 'react';
import buttonStyles from '../../styles/UI/Button.module.css';

export const TabButton = ({
	classes,
	title,
	active,
	onClick,
}: {
	classes?: string;
	title: string;
	active?: boolean;
	onClick: () => void;
}) => {
	const classList = `${buttonStyles.tabButton} ${active ? buttonStyles.tabButtonActive : null} ${
		classes ? classes : null
	}`;

	return (
		<button className={classList} onClick={onClick}>
			{title}
		</button>
	);
};
