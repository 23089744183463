import api, { API_PATHS } from './api';
import { BaggageState } from '../types';
import { AxiosError } from 'axios';

type BaggageApiResult = {
	success: boolean;
	resultMessage?: string;
};

export const setActualFirstBag = async (state: BaggageState): Promise<BaggageApiResult> => {
	try {
		const response = await api.post(API_PATHS.postFirstBag, state);

		if (response.status === 208) {
			return {
				success: false,
				resultMessage: 'First bag has already been registered',
			};
		}

		if (response.status < 300) {
			return {
				success: true,
			};
		}
		// Default clause
		return { success: false, resultMessage: 'Something went wrong' };
	} catch (error: any) {
		if (error.response && error.response.status === 404) {
			// If 404, the forwarded flightRef does not match any of the flights in our database.
			return { success: false, resultMessage: 'Flight not found' };
		}
		return { success: false, resultMessage: 'Something went wrong' };
	}
};

export const setActualLastBag = async (state: BaggageState): Promise<BaggageApiResult> => {
	try {
		const response = await api.post(API_PATHS.postLastBag, state);

		if (response.status === 208) {
			return {
				success: false,
				resultMessage: 'Last bag has already been registered',
			};
		}

		if (response.status < 300) {
			return {
				success: true,
			};
		}
		// Default clause
		return { success: false, resultMessage: 'Something went wrong' };
	} catch (error: any) {
		if (error.response && error.response.status === 404) {
			// If 404, the forwarded flightRef does not match any of the flights in our database.
			return { success: false, resultMessage: 'Flight not found' };
		} else if (error.response && error.response.status === 412) {
			// PRECONDITION FAILED - On setting last bag, if first bag is not set.
			return { success: false, resultMessage: 'You need to set first bag first' };
		}
		return { success: false, resultMessage: 'Something went wrong' };
	}
};

export const setActualNoBax = async (state: BaggageState): Promise<BaggageApiResult> => {
	try {
		const response = await api.post(API_PATHS.postNoBax, state);

		if (response.status === 208) {
			return {
				success: false,
				resultMessage: 'No bax has already been reported',
			};
		}

		if (response.status < 300) {
			return {
				success: true,
			};
		}
		// Default clause
		return { success: false, resultMessage: 'Something went wrong' };
	} catch (error: any) {
		if (error.response && error.response.status === 404) {
			// If 404, the forwarded flightRef does not match any of the flights in our database.
			return { resultMessage: 'Flight not found', success: false };
		} else if (error.response && error.response.status === 412) {
			// PRECONDITION FAILED - On setting no bax and the state of time points does not allow the action.

			return { resultMessage: 'You need to clear first bag and last bag', success: false };
		}
		return { success: false, resultMessage: 'Something went wrong' };
	}
};
