import { EEUHome, AEUHome, TEUHome, NotFound, OEUHome } from './pages';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RootLayout } from './pages/RootLayout';
import RefreshHelper, { useRefreshCheck } from './utils/refreshOnNewBuild';
import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import { addKeycloakInterceptorWithKeycloakInstance, addSecurityDisabledResponseInterceptor } from './service/api';
import { useDispatch } from 'react-redux';
import { setInterceptorIsReady } from './store/apiConfigurationstate';

const router = createBrowserRouter([
	{
		path: '/',
		element: <RootLayout />,
		children: [
			{ path: '/oeu', element: <OEUHome /> },
			{ path: '/eeu', element: <EEUHome /> },
			{ path: '/aeu', element: <AEUHome /> },
			{ path: '/teu', element: <TEUHome /> },
			{ path: '*', element: <NotFound /> },
		],
	},
]);

const App = () => {
	const dispatch = useDispatch();
	const { shouldHardRefresh, terminate } = useRefreshCheck();
	const securityDisabled = JSON.parse(window._env_.REACT_APP_DISABLE_SECURITY ?? 'false');

	// To do: Pass kc to store?
	const [isLogin, keycloakObject] = useAuth();

	useEffect(() => {
		if (securityDisabled) {
			// If security is disabled, we still need to signal to the application that we are ready to query
			addSecurityDisabledResponseInterceptor();
			dispatch(setInterceptorIsReady());
			return;
		}
		if (keycloakObject) {
			addKeycloakInterceptorWithKeycloakInstance(keycloakObject);
			dispatch(setInterceptorIsReady());
		} 
	}, [keycloakObject]);

	useEffect(() => {
		if (shouldHardRefresh) {
			terminate();
			RefreshHelper.forceReload();
		}
	}, [shouldHardRefresh]);

	return <main>{isLogin && <RouterProvider router={router} />}</main>;
};
export default App;
