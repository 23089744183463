import { Label } from '../UI/index';
import ModalStyles from '../../styles/modal/BaggageInfo.module.css';
import { BaggageEntryFlight } from '../../types';
import { getBeltLabel } from '../../service/beltDisplayNameService';

export const ModalBaggageInfo = ({ flight }: { flight: BaggageEntryFlight }) => {
	return (
		<div className="flex rounded-lg overflow-hidden">
			<div className={`${ModalStyles.baggageInfoWrapper} bg-purple text-white text-[20px]`}>
				<p className={`${ModalStyles.baggageInfoText}`}>Local arrival</p>
				<p className="text-xl font-bold">Belt {getBeltLabel(flight.arrivalBeltName)}</p>
				<p className="font-bold">{flight.arrivalBeltUnloadDirection.toUpperCase()}</p>
			</div>

			<div className={`${ModalStyles.baggageInfoWrapper} bg-yellow-CPH text-[20px]`}>
				<p className={ModalStyles.baggageInfoText}>Transfer</p>
				<p className="text-xl font-bold">Belt {getBeltLabel(flight.transferBeltName)}</p>
				<p className="font-bold">{flight.arrivalBeltUnloadDirection.toUpperCase()}</p>
			</div>
		</div>
	);
};
