import { createSlice } from '@reduxjs/toolkit';
import { BaggageEntryFlightState } from '../../types/baggageEntryFlight';
import { BaggageEntryFlight, HandlerRefPair } from '../../types';
import { handlerRefPairForAllHandlers } from '../../utils/handlers';
import { getBaggageEntryFlightState, updateBaggageEntryFlightState } from './actions';
import store from '..';

export interface BaggageEntryFlightStateSlice {
	error: boolean;
	baggageEntryFlightState: BaggageEntryFlightState | undefined;
	lastModified?: string;
	selectedHandler: HandlerRefPair;
}

const initialState: BaggageEntryFlightStateSlice = {
	error: false,
	baggageEntryFlightState: undefined,
	selectedHandler: handlerRefPairForAllHandlers,
};

const baggageEntryFlightStateSlice = createSlice({
	name: 'baggage-entry-flight',
	initialState,
	reducers: {
		setSelectedHandler(state, handlerRef) {
			state.selectedHandler = handlerRef.payload;
		},

		setCurrentCheckInData(state, checkInStatus): void {
			// run throught the data that is being currently shown on the monitor, to update "checkedIn" related values live.
			const data = state.baggageEntryFlightState?.data?.map((flight: BaggageEntryFlight) => {
				if (flight.flightRef === checkInStatus.payload.flightRef) {
					return {
						...flight,
						noArrivalBax: checkInStatus.payload.noArrivalBax,
						noTransferBax: checkInStatus.payload.noTransferBax,
						entryAtGate: checkInStatus.payload.gate,
					};
				}
				return flight;
			});

			if (state.baggageEntryFlightState && data) {
				state.baggageEntryFlightState = {
					...state.baggageEntryFlightState,
					data: data,
				};
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getBaggageEntryFlightState.rejected, (state) => {
			state.error = true;
			state.baggageEntryFlightState = undefined;
		});
		builder.addCase(getBaggageEntryFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null) {
				state.baggageEntryFlightState = action.payload;
			} else {
				state.baggageEntryFlightState = undefined;
			}
		});
		builder.addCase(updateBaggageEntryFlightState.fulfilled, (state, action) => {
			state.error = false;
			if (action.payload !== null && action.payload !== undefined) {
				state.baggageEntryFlightState = action.payload;
			}
		});
		builder.addCase(updateBaggageEntryFlightState.rejected, (state) => {
			state.error = true;
			state.baggageEntryFlightState = undefined;
		});
	},
});

export const { setCurrentCheckInData, setSelectedHandler } = baggageEntryFlightStateSlice.actions;

export default baggageEntryFlightStateSlice.reducer;
