import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { HandlerRefPair } from '../../../types';
// Components
import { TabButton } from '../../UI/index';
// styles
import homepageStyles from '../../../styles/homepage/Homepage.module.css';
import { setSelectedHandler } from '../../../store/baggageEntryFlightState';
// Other
import { handlerRefPairs, handlerRefPairForAllHandlers } from '../../../utils/handlers';

export const TabBarNavigation = ({
	classes,
	onClick,
}: {
	classes?: string;
	onClick: (handerRef: HandlerRefPair) => void;
}) => {
	const dispatch = useAppDispatch();
	const classList = `${homepageStyles.tabBarWrapper}  ${classes ? classes : null}`;
	// Used to filter on handlers

	const selectedHandler = useAppSelector(
		(state) => state.baggageEntryFlightState.selectedHandler
	);

	const filterOnHandler = (handlerRefPair: HandlerRefPair) => {
		onClick(handlerRefPair);
	};

	useEffect(() => {
		filterOnHandler(selectedHandler);
	}, [selectedHandler]);

	return (
		<div className={classList}>
			<TabButton
				title={handlerRefPairForAllHandlers.handlerName}
				onClick={() => dispatch(setSelectedHandler(handlerRefPairForAllHandlers))}
				key={0}
				active={
					!selectedHandler ||
					selectedHandler.handlerName === handlerRefPairForAllHandlers.handlerName
				}
			/>
			{handlerRefPairs.map((x, index) => (
				<TabButton
					title={x.handlerName}
					onClick={() => dispatch(setSelectedHandler(x))}
					key={index + 1}
					active={selectedHandler?.handlerName === x.handlerName}
				/>
			))}
		</div>
	);
};
