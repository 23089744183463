import React from 'react';
import buttonStyles from '../../styles/UI/Button.module.css';

export const ModalButton = ({
	classes,
	icon,
	title,
	callBackFunction,
}: {
	classes?: string;
	icon?: React.ReactNode;
	title: string;
	callBackFunction: () => void;
}) => {
	const classList = `${buttonStyles.modalButton} ${buttonStyles.button} ${
		classes ? classes : ''
	}`;

	const handleClick = () => {
		callBackFunction();
	};

	return (
		<button className={classList} onClick={handleClick}>
			{/* style icon in parent component width={15} height={15} */}
			{icon && icon}
			{title}
		</button>
	);
};
